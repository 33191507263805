import React, { useEffect } from 'react';
import { setSharedValue, deleteSharedValue } from '../services/sharedStore';
import { SHAREDCACHE_APP_ACTIVE } from '../consts';

/**
 * Component that manages app active state tracking
 * This is particularly important for iOS to prevent notifications 
 * from showing while the app is in the foreground
 */
export const AppStateManager: React.FC = () => {
  useEffect(() => {
    // Mark app as active immediately on mount
    setSharedValue(SHAREDCACHE_APP_ACTIVE, 'true');
    
    // iOS-specific heartbeat to ensure visibility is correctly tracked
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let heartbeatInterval: NodeJS.Timeout | null = null;
    
    if (isIOS) {
      // For iOS, we use a heartbeat to keep updating the active status
      // This helps with iOS PWA quirks
      heartbeatInterval = setInterval(() => {
        if (document.visibilityState === 'visible') {
          setSharedValue(SHAREDCACHE_APP_ACTIVE, 'true');
        }
      }, 10000); // Update every 10 seconds while visible
    }
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setSharedValue(SHAREDCACHE_APP_ACTIVE, 'true');
      } else {
        deleteSharedValue(SHAREDCACHE_APP_ACTIVE);
      }
    };
    
    const handleFocus = () => {
      setSharedValue(SHAREDCACHE_APP_ACTIVE, 'true');
    };
    
    const handleBlur = () => {
      // Don't clear on blur for iOS as it might be temporary
      if (!isIOS) {
        deleteSharedValue(SHAREDCACHE_APP_ACTIVE);
      }
    };
    
    // Add event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    
    // Special iOS resume event (for PWAs)
    if (isIOS) {
      window.addEventListener('resume', () => {
        console.log('iOS app resumed');
        setSharedValue(SHAREDCACHE_APP_ACTIVE, 'true');
      });
    }
    
    return () => {
      // Cleanup
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      if (isIOS) window.removeEventListener('resume', handleFocus);
      if (heartbeatInterval) clearInterval(heartbeatInterval);
      
      // Delete active state when component unmounts unless page is still visible
      // This helps with page navigation vs app closing
      if (document.visibilityState !== 'visible') {
        deleteSharedValue(SHAREDCACHE_APP_ACTIVE);
      }
    };
  }, []);
  
  return null; // This is just a logical component with no UI
};
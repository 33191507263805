import React, { useCallback, useEffect } from "react";
import { Chat } from "../models/chat";
import ChatHeader from "./ChatHeader";import MessageList from "./MessageList";
import { useChatMessages } from "../hooks/useChatMessages";
import { useChatSync } from "../hooks/useChatSync";
import { useChatWebSocket } from "../hooks/useChatWebSocket";
import { useAppLaunchState } from "../../../hooks/useAppLaunchState";
import { useApolloClient } from "@apollo/client";
import { LifecycleAwareApolloClient } from "../../../services/gqlClients";
import ChatInputArea2 from "./ChatInputArea2";

interface ChatWindowProps {
  chat: Chat;
  currentUserId?: string;
  onSelectChat?: (chat: Chat) => void;
  onBack?: () => void;
  isMobile?: boolean;
}

const ChatWindow2: React.FC<ChatWindowProps> = ({
  chat,
  currentUserId,
  onSelectChat,
  onBack,
  isMobile,
}) => {
  const client = useApolloClient();
  const { launchState, clearLaunchState } = useAppLaunchState();

  // Core message data and operations
  const {
    messages,
    loading,
    fetchMore,
    refetch,
    addMessage,
    updateMessageReadStatus,
    markMessageAsRead,
  } = useChatMessages(chat.id, currentUserId || "");

  // Handles sync between chats, reconnection, and visibility
  const {
    syncMessages, 
    scrollToBottom,
    messagesEndRef,
    loadMoreRef,
    messagesContainerRef,
    shouldScrollToBottom,
    handleScroll,
    isSyncing, 
  } = useChatSync({
    chatId: chat.id,
    messages,
    fetchMore,
    refetch,
  });

  // WebSocket event handling consolidated
  const { typingUsers } = useChatWebSocket({
    chat,
    currentUserId,
    onNewMessage: addMessage,
    onReadStatusChange: updateMessageReadStatus,
    shouldScrollToBottom,
    scrollToBottom,
    fetchMissedMessages: syncMessages,
    client,
  });

  // Handle app launches from notifications
  useEffect(() => {
    if (
      launchState &&
      launchState.source === "notification" &&
      launchState.chatId === chat.id
    ) {
      console.log(
        "App launched from notification for this chat, forcing fresh data"
      );

      const isiOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isiOS) {
        console.log(
          "iOS device detected, forcing complete cache invalidation for chat messages"
        );

        // Invalidate all cached message data for this chat
        client.cache.evict({
          fieldName: "messages",
          broadcast: true,
        });

        client.cache.evict({
          fieldName: "chat",
          args: { id: chat.id },
          broadcast: true,
        });

        client.cache.gc();
      }

      // Notify Apollo client about the launch context
      (
        client as unknown as LifecycleAwareApolloClient
      ).notifyAppLaunchedFromNotification(
        chat.id,
        launchState.messageId ?? undefined
      );

      const timeout = isiOS ? 1500 : 800;

      const timer = setTimeout(() => {
        console.log(
          `Fetching fresh data after notification launch (${timeout}ms delay)`
        );

        if (isiOS) {
          refetch({
            chatId: chat.id,
            limit: 40, // Get more messages on iOS
          }).then(() => {
            console.log(
              "Refetch complete after notification, scrolling to bottom"
            );
            scrollToBottom();
          });
        } else {
          syncMessages();
        }

        clearLaunchState();
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [
    launchState,
    chat.id,
    clearLaunchState,
    client,
    syncMessages,
    refetch,
    scrollToBottom,
  ]);

  // Callback for when a message becomes visible
  const handleMessageVisible = useCallback(
    (messageId: string) => {
      markMessageAsRead(messageId);
    },
    [markMessageAsRead]
  );

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <ChatHeader
        chat={chat}
        onSelectChat={onSelectChat}
        onBack={onBack}
        isMobile={isMobile}
      />

      <MessageList
        messages={messages}
        currentUserId={currentUserId || ""}
        participants={chat.participants}
        typingUsers={typingUsers}
        loading={loading || isSyncing}
        chatId={chat.id}
        messagesContainerRef={messagesContainerRef}
        loadMoreRef={loadMoreRef}
        messagesEndRef={messagesEndRef}
        onScroll={handleScroll}
        onMessageVisible={handleMessageVisible}
      />

      <ChatInputArea2 chat={chat} currentUserId={currentUserId} onMessageSent={scrollToBottom} />
    </div>
  );
};

export default ChatWindow2;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { ACTIVE_CHAT_KEY } from "../features/chat/consts";
import { SHAREDCACHE_ACTIVE_CHAT } from "../consts";
import {
  ConnectionStatus,
  WebSocketContext,
} from "../features/chat/context/WebSocketContext";
import { Chat } from "../features/chat/models/chat";
import { deleteSharedValue, setSharedValue } from "../services/sharedStore";

// Action types
type ActionType =
  | { type: "SET_CHAT_START"; payload: Chat | null }
  | { type: "SET_CHAT_COMPLETE"; payload: Chat | null }
  | { type: "SET_CHAT_ERROR"; payload: Error };

// State interface
interface ActiveChatState {
  activeChat: Chat | null;
  previousChat: Chat | null;
  isChangingChat: boolean;
  error: Error | null;
}

// Initial state
const initialState: ActiveChatState = {
  activeChat: null,
  previousChat: null,
  isChangingChat: false,
  error: null,
};

// Simple reducer
function activeChatReducer(
  state: ActiveChatState,
  action: ActionType
): ActiveChatState {
  switch (action.type) {
    case "SET_CHAT_START":
      return {
        ...state,
        previousChat: state.activeChat,
        isChangingChat: true,
        error: null,
      };

    case "SET_CHAT_COMPLETE":
      return {
        ...state,
        activeChat: action.payload,
        isChangingChat: false,
        error: null,
      };

    case "SET_CHAT_ERROR":
      return {
        ...state,
        isChangingChat: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

// Context interface
interface ActiveChatContextType {
  activeChat: Chat | null;
  activeChatId: string | null;
  isChangingChat: boolean;
  setActiveChat: (chat: Chat | null) => Promise<void>;
  isActiveChatId: (chatId: string) => boolean;
  leaveChat: (chatId: string, permanent?: boolean) => void;
}

const ActiveChatContext = createContext<ActiveChatContextType>({
  activeChat: null,
  activeChatId: null,
  isChangingChat: false,
  setActiveChat: async () => {},
  isActiveChatId: () => false,
  leaveChat: () => {},
});

export const useActiveChat = () => useContext(ActiveChatContext);

export const ActiveChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(activeChatReducer, initialState);
  const { activeChat, isChangingChat } = state;
  const activeChatId = activeChat?.id || null;

  const [searchParams] = useSearchParams();
  const ws = useContext(WebSocketContext);

  // Track the latest active chat ID for reconnection purposes
  const activeChatRef = useRef<string | null>(null);

  // Handle chat URL param
  const chatIdFromUrl = searchParams.get("chat_id");

  // Main function to change active chat
  const setActiveChat = useCallback(
    async (chat: Chat | null) => {
      const newChatId = chat?.id || null;
      const currentChatId = activeChatId;

      // Skip if we're already on this chat or changing chats
      if (newChatId === currentChatId || isChangingChat) {
        return;
      }

      try {
        // 1. Update UI immediately with new chat for fast feedback
        dispatch({ type: "SET_CHAT_START", payload: chat });

        // 2. Update storage immediately too
        if (newChatId) {
          localStorage.setItem(ACTIVE_CHAT_KEY, newChatId);
          setSharedValue(SHAREDCACHE_ACTIVE_CHAT, newChatId);
          activeChatRef.current = newChatId;

          // 3. Broadcast event early for other components to start preparing
          window.dispatchEvent(
            new CustomEvent("chat-changed", {
              detail: { chatId: newChatId, phase: "starting" },
            })
          );
        } else {
          localStorage.removeItem(ACTIVE_CHAT_KEY);
          deleteSharedValue(SHAREDCACHE_ACTIVE_CHAT);
          activeChatRef.current = null;
        }

        // 4. Handle WebSocket operations (can happen in background)
        if (ws?.connectionStatus === ConnectionStatus.OPEN) {
          // Handle previous chat
          if (currentChatId) {
            console.log(`Leaving chat: ${currentChatId}`);
            ws.sendLeave(currentChatId);
          }

          // Join new chat immediately - don't wait for leave to complete
          if (newChatId) {
            console.log(`Joining chat: ${newChatId}`);
            ws.sendJoin(newChatId);
          }
        }

        // 5. Complete the transition
        dispatch({ type: "SET_CHAT_COMPLETE", payload: chat });

        // 6. Broadcast final event
        window.dispatchEvent(
          new CustomEvent("chat-changed", {
            detail: { chatId: newChatId, phase: "complete" },
          })
        );
      } catch (error) {
        console.error("Error changing chats:", error);
        dispatch({ type: "SET_CHAT_ERROR", payload: error as Error });
      }
    },
    [activeChatId, isChangingChat, ws]
  );

  // Leave chat explicitly (for UI actions)
  const leaveChat = useCallback(
    (chatId: string, permanent = false) => {
      if (!chatId || !ws) return;

      // Send leave message
      if (ws.connectionStatus === ConnectionStatus.OPEN) {
        ws.sendLeave(chatId);
      }

      // If permanent, update state
      if (permanent && chatId === activeChatId) {
        setActiveChat(null);
      }
    },
    [activeChatId, setActiveChat, ws]
  );

  // Check if a given chatId matches the active chat
  const isActiveChatId = useCallback(
    (chatId: string | null): boolean => {
      if (!chatId) return false;
      return chatId === activeChatId;
    },
    [activeChatId]
  );

  // Handle URL changes
  useEffect(() => {
    if (chatIdFromUrl && chatIdFromUrl !== activeChatId && !isChangingChat) {
      // Store for reference until we have the full chat object
      localStorage.setItem(ACTIVE_CHAT_KEY, chatIdFromUrl);
      activeChatRef.current = chatIdFromUrl;
      setSharedValue(SHAREDCACHE_ACTIVE_CHAT, chatIdFromUrl);
    }
  }, [chatIdFromUrl, activeChatId, isChangingChat]);

  // Handle visibility and focus changes
  useEffect(() => {
    const handleVisibilityAndFocus = () => {
      // Only handle when app becomes visible and not changing chats
      if (
        document.visibilityState === "visible" &&
        document.hasFocus() &&
        !isChangingChat
      ) {
        const chatId = activeChatRef.current;
        if (!chatId) return;

        // If we're connected, refresh the chat join
        if (ws?.connectionStatus === ConnectionStatus.OPEN) {
          console.log(`Refreshing chat connection: ${chatId}`);
          ws.sendJoin(chatId);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityAndFocus);
    window.addEventListener("focus", handleVisibilityAndFocus);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityAndFocus
      );
      window.removeEventListener("focus", handleVisibilityAndFocus);
    };
  }, [isChangingChat, ws]);

  // Handle WebSocket connection changes
  useEffect(() => {
    if (ws?.connectionStatus === ConnectionStatus.OPEN && !isChangingChat) {
      const chatId = activeChatRef.current;
      if (!chatId) return;

      console.log(`WebSocket connected, ensuring chat: ${chatId}`);
      ws.sendJoin(chatId);
    }
  }, [ws?.connectionStatus, isChangingChat, ws]);

  // Context value
  const contextValue = {
    activeChat,
    activeChatId,
    isChangingChat,
    setActiveChat,
    isActiveChatId,
    leaveChat,
  };

  return (
    <ActiveChatContext.Provider value={contextValue}>
      {children}
    </ActiveChatContext.Provider>
  );
};

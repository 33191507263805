import React, {
  createContext,
  useContext,
  useRef,
  useState,
  ReactNode,
} from "react";

// Status types for tracking uploads
export type UploadStatus =
  | "idle"
  | "uploading"
  | "success"
  | "error"
  | "cancelled";

// Interface for upload items
export interface UploadItem {
  id: string;
  file: File;
  previewUrl: string;
  progress: number;
  status: UploadStatus;
  context: string; // 'chat', 'profile', 'event', etc.
  error?: Error;
}

// Interface for the upload context state
interface FileUploadState {
  uploads: Record<string, UploadItem>;
  activeUploadId: string | null;
}

// Interface for the upload context actions
interface FileUploadActions {
  startUpload: (id: string, file: File, context: string) => void;
  updateUploadProgress: (id: string, progress: number) => void;
  completeUpload: (id: string, success: boolean, error?: Error) => void;
  cancelUpload: (id: string) => void;
  getUpload: (id: string) => UploadItem | undefined;
  createPreviewUrl: (file: File) => Promise<string>;
  getUploadFile: (id: string) => File | undefined;
}

// Combined interface for the context
interface FileUploadContextType extends FileUploadState, FileUploadActions {}

// Create the context
const FileUploadContext = createContext<FileUploadContextType | undefined>(
  undefined
);

// Provider component
export const FileUploadProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // State to track all uploads
  const [uploads, setUploads] = useState<Record<string, UploadItem>>({});
  const [activeUploadId, setActiveUploadId] = useState<string | null>(null);

  // Refs to track abort controllers for each upload
  const abortControllersRef = useRef<Record<string, AbortController>>({});

  // Create file preview
  const createPreviewUrl = async (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    });
  };

  // Start a new upload
  const startUpload = async (id: string, file: File, context: string) => {
    // Create preview
    const previewUrl = await createPreviewUrl(file);

    // Create abort controller
    const abortController = new AbortController();
    abortControllersRef.current[id] = abortController;

    // Add to uploads state
    setUploads((prev) => ({
      ...prev,
      [id]: {
        id,
        file,
        previewUrl,
        progress: 0,
        status: "uploading",
        context,
      },
    }));

    // Set as active upload
    setActiveUploadId(id);
  };

  // Update progress
  const updateUploadProgress = (id: string, progress: number) => {
    setUploads((prev) => {
      const upload = prev[id];
      if (!upload) return prev;

      return {
        ...prev,
        [id]: {
          ...upload,
          progress,
        },
      };
    });
  };

  const fileCache = useRef<Record<string, File>>({});

  // Complete an upload (success or failure)
  const completeUpload = (id: string, success: boolean, error?: Error) => {
    setUploads((prev) => {
      const upload = prev[id];
      if (!upload) return prev;

      // If this is an error, store the file for retry
      if (!success && upload.file) {
        fileCache.current[id] = upload.file;
      }
      return {
        ...prev,
        [id]: {
          ...upload,
          status: success ? "success" : "error",
          error: error,
        },
      };
    });

    // Clear abort controller
    if (abortControllersRef.current[id]) {
      delete abortControllersRef.current[id];
    }

    // Clear active upload if this was it
    if (activeUploadId === id) {
      setActiveUploadId(null);
    }
  };

  // Cancel an upload
  const cancelUpload = (id: string) => {
    // Abort the request if it's in progress
    const controller = abortControllersRef.current[id];
    if (controller) {
      controller.abort();
      delete abortControllersRef.current[id];
    }

    // Update status
    setUploads((prev) => {
      const upload = prev[id];
      if (!upload) return prev;

      return {
        ...prev,
        [id]: {
          ...upload,
          status: "cancelled",
        },
      };
    });

    // Clear active upload if this was it
    if (activeUploadId === id) {
      setActiveUploadId(null);
    }
  };

  const getUploadFile = (id: string): File | undefined => {
    return fileCache.current[id];
  };

  // Get specific upload details
  const getUpload = (id: string): UploadItem | undefined => {
    return uploads[id];
  };

  // Create context value
  const contextValue: FileUploadContextType = {
    // State
    uploads,
    activeUploadId,

    // Actions
    startUpload,
    updateUploadProgress,
    completeUpload,
    cancelUpload,
    getUpload,
    createPreviewUrl,
    getUploadFile,
  };

  return (
    <FileUploadContext.Provider value={contextValue}>
      {children}
    </FileUploadContext.Provider>
  );
};

// Custom hook to use the FileUploadContext
export const useFileUploadContext = () => {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error(
      "useFileUploadContext must be used within a FileUploadProvider"
    );
  }
  return context;
};

import { BubbleChatIcon } from "hugeicons-react";

type FAQItem = {
  question: string;
  answer: string | JSX.Element;
};

const faqs: FAQItem[] = [
  {
    question: "Come funziona GioJoy?",
    answer:
      "GioJoy è una piattaforma che permette di organizzare eventi e attività, facilitando la gestione e la partecipazione degli utenti in modo semplice e intuitivo.",
  },
  {
    question: "Come posso entrare nei gruppi WhatsApp associati a GioJoy?",
    answer: (
      <>
        Esistono due gruppi WhatsApp ufficiali di GioJoy pensati per rimanere
        aggiornati sugli eventi e interagire con altri partecipanti. Se vuoi
        entrare, usa il link{" "}
        <a
          href="https://chat.whatsapp.com/J2jbREOjJb6LDzgt50vjpN"
          className="text-blue-400 hover:text-blue-500"
        >
          supporto WhatsApp di GioJoy
        </a>{" "}
        per richiedere l'accesso.
      </>
    ),
  },
  {
    question: "Quali eventi posso trovare su GioJoy?",
    answer: (
      <>
        GioJoy offre una vasta gamma di eventi e attività, dal menù{" "}
        <a href="/events" className="text-blue-400 hover:text-blue-500">
          Eventi
        </a>{" "}
        è possibile vedere l'elenco diviso per mese, è anche possibile usare il{" "}
        <a href="/calendar" className="text-blue-400 hover:text-blue-500">
          Calendario
        </a>{" "}
        per visualizzare i giorni in cui sono programmati eventi.
      </>
    ),
  },
  {
    question: "Come posso creare un evento?",
    answer: (
      <>
        Per creare un evento devi essere registrat@ su GioJoy. Una volta
        effettuato l'accesso, clicca sul pulsante{" "}
        <a href="/events/create" className="text-blue-400 hover:text-blue-500">
          Crea evento
        </a>{" "}
        e compila il form con i dati richiesti.
      </>
    ),
  },
  {
    question: "Posso modificare un evento dopo averlo pubblicato?",
    answer: (
      <>
        Sì, puoi, non è permesso modificare però la data e la possibilità di far
        partecipare figl@. Questi due campi sono bloccati per garantire a chi si
        è iscritto che non cambino le condizioni a cui ha aderito. Per
        modificare l'evento devi essere loggato, andando nella scheda del tuo
        evento utilizza il menù accessibile dai tre pallini in alto a destra.
        <img src="/faq/sample_event_edit.png" alt="esempio" />
      </>
    ),
  },
  {
    question: "Come mi iscrivo ad un evento?",
    answer: (
      <>
        Dalla scheda dell'evento clicca sul pulsante{" "}
        <strong>'Iscriviti'</strong>. Se l'evento prevede la partecipazione di
        figl@, è possibile aggiungerli durante la procedura di iscrizione. Puoi
        anche specificare se porterai ospiti con te.
      </>
    ),
  },
  {
    question: "Posso cancellare la mia iscrizione ad un evento?",
    answer: (
      <>
        Sì, puoi annullare l’iscrizione selezionando il tasto{" "}
        <strong>‘Cancella Iscrizione’</strong> nella scheda dell’evento a cui
        sei iscrittə, a condizione di essere loggatə.<strong>Nota</strong>:
        alcuni eventi potrebbero non consentire la cancellazione poco prima
        dell'inizio.
      </>
    ),
  },
  {
    question: "Come si gestiscono gli eventi con posti limitati?",
    answer:
      "Se l'evento prevede un numero massimo di partecipanti, una volta raggiunto il limite non sarà più possibile iscriversi. Se un iscritto annulla la sua partecipazione il posto sarà disponibile per un altro utente.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Domande frequenti di GioJoy
            </h2>
            <p className="mt-4 text-pretty text-base/7 text-gray-600">
              Non trovi le risposte che stai cercando? Contattaci tramite il
              <a
                href="https://chat.whatsapp.com/J2jbREOjJb6LDzgt50vjpN"
                className="inline-flex items-center gap-1 font-semibold text-indigo-600 hover:text-indigo-500"
              >
                <BubbleChatIcon />
                supporto WhatsApp di GioJoy
              </a>
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base/7 font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Message } from "../models/message";
import { Chat } from "../models/chat";
import {
  WebSocketContext,
  ConnectionStatus,
} from "../context/WebSocketContext";
import {
  kMessageTypeChatJoin,
  kMessageTypeChatLeave,
  kMessageTypeMessage,
  kMessageTypeMessageRead,
  kMessageTypeTyping,
  WebSocketMessage,
} from "../context/WebSocketMessage";
import { ApolloClient } from "@apollo/client";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";
import { messageManager } from "../services/msgManager";

interface UseChatWebSocketProps {
  chat: Chat;
  currentUserId?: string;
  onNewMessage: (message: Message) => void;
  onReadStatusChange: (messageIds: string[], userId: string) => void;
  shouldScrollToBottom: boolean;
  scrollToBottom: () => void;
  fetchMissedMessages: (lastMessageId: string) => Promise<any>;
  client: ApolloClient<any>;
}

export const useChatWebSocket = ({
  chat,
  currentUserId,
  onNewMessage,
  onReadStatusChange,
  shouldScrollToBottom,
  scrollToBottom,
  fetchMissedMessages,
  client,
}: UseChatWebSocketProps) => {
  const ws = useContext(WebSocketContext);
  const [typingUsers, setTypingUsers] = useState<Set<string>>(new Set());

  // Connection state tracking
  const [wasDisconnected, setWasDisconnected] = useState(false);
  const lastConnectionStatus = useRef<ConnectionStatus | null>(null);
  const lastSyncTimeRef = useRef<Date | null>(null);

  // Typing indicator handler
  const onTypingChange = useCallback((userId: string, isTyping: boolean) => {
    setTypingUsers((prev) => {
      const newSet = new Set(prev);
      if (isTyping) {
        newSet.add(userId);
      } else {
        newSet.delete(userId);
      }
      return newSet;
    });
  }, []);

  // Handle WebSocket connection state changes
  useEffect(() => {
    if (!ws) return;

    const currentStatus = ws.connectionStatus;

    // Track reconnections
    if (
      lastConnectionStatus.current !== ConnectionStatus.OPEN &&
      currentStatus === ConnectionStatus.OPEN &&
      wasDisconnected
    ) {
      console.log("WebSocket reconnected, checking for missed messages");

      // Get the last known message ID for this chat
      const lastMessageId = messageManager.getLastKnownMessageId(chat.id);

      if (lastMessageId) {
        console.log(`Fetching missed messages since ${lastMessageId}`);

        // Important: Add a slight delay to ensure WebSocket handshake is complete
        setTimeout(() => {
          fetchMissedMessages(lastMessageId)
            .then((result) => {
              if (result?.data?.messages?.edges?.length) {
                console.log(
                  `Retrieved ${result.data.messages.edges.length} missed messages after reconnection`
                );
                lastSyncTimeRef.current = new Date();
              } else {
                console.log("No new messages found after reconnection");
              }
            })
            .catch((error) => {
              console.error("Failed to fetch missed messages:", error);

              // On error, try a complete refresh as fallback
              console.log("Attempting full message refresh as fallback");
              client.refetchQueries({
                include: ["Messages"],
              });
            });
        }, 500);
      } else {
        console.log("No previous message ID found, skipping sync");
      }

      setWasDisconnected(false);
    } else if (
      lastConnectionStatus.current === ConnectionStatus.OPEN &&
      currentStatus !== ConnectionStatus.OPEN
    ) {
      console.log("WebSocket disconnected");
      setWasDisconnected(true);
    }

    lastConnectionStatus.current = currentStatus;
  }, [
    ws?.connectionStatus,
    wasDisconnected,
    fetchMissedMessages,
    ws,
    chat.id,
    client,
  ]);

  // WebSocket message handler
  useEffect(() => {
    if (!ws) return;

    const handler = (message: WebSocketMessage) => {
      // Safe access to properties that might be undefined
      const msgType = message.type;
      const msgChatId = message.data?.chatId;
      const msgUserId = message.data?.userId || message.data?.senderId;

      if (msgChatId === chat.id) {
        switch (msgType) {
          case kMessageTypeMessage:
            console.log("Received chat message:", JSON.stringify(message));

            // Handle actual chat messages
            if (msgUserId !== currentUserId) {
              onNewMessage(message.data);
              if (shouldScrollToBottom) {
                scrollToBottom();
              }
              messageManager.trackLatestMessage(chat.id, message.data.id);
            }
            break;

          case kMessageTypeMessageRead:
            if (
              message.data.messageIds &&
              Array.isArray(message.data.messageIds)
            ) {
              onReadStatusChange(message.data.messageIds, message.data.userId);
            }
            break;

          case kMessageTypeTyping:
            onTypingChange(msgUserId, message.data.typing);
            break;

          case kMessageTypeChatJoin:
          case kMessageTypeChatLeave:
            // Could handle join/leave notifications here if needed
            break;
        }
      } else if (msgType === kMessageTypeMessage && msgChatId) {
        // Process background message
        try {
          const newMessage = message.data;

          // Validate the message has required fields
          if (!newMessage.id || !newMessage.content) {
            console.warn("Skipping invalid background message", newMessage);
            return;
          }

          if (!newMessage.__typename) {
            newMessage.__typename = "Message";
          }
          // Write to cache
          const newMessageRef = client.cache.writeFragment({
            data: newMessage,
            fragment: MESSAGE_FIELDS_FRAGMENT,
          });

          // Update both sort directions in cache
          ["DESC", "ASC"].forEach((sortDirection) => {
            client.cache.modify({
              fields: {
                messages(existing = { edges: [] }, { storeFieldName }) {
                  if (
                    !storeFieldName.includes(`"chatId":"${msgChatId}"`) ||
                    !storeFieldName.includes(
                      `"sortDirection":"${sortDirection}"`
                    )
                  ) {
                    return existing;
                  }

                  // Rest of cache update logic...
                  // (Keeping your existing implementation)
                },
              },
            });
          });

          // Update chat's last message
          client.cache.modify({
            id: `Chat:${msgChatId}`,
            fields: {
              lastMessage: () => newMessageRef,
              unreadCount: (count: number = 0) => count + 1,
            },
          });

          // Always track in MessageManager
          messageManager.trackLatestMessage(msgChatId, newMessage.id);

          // For background chats
          window.dispatchEvent(
            new CustomEvent("background-message", {
              detail: { chatId: msgChatId, messageId: newMessage.id },
            })
          );
        } catch (e) {
          console.error(`Failed to process message for chat ${msgChatId}:`, e);
        }
      }
    };

    ws.onMessage(handler);
    return () => ws.removeMessageHandler(handler);
  }, [
    ws,
    chat.id,
    currentUserId,
    onNewMessage,
    onReadStatusChange,
    onTypingChange,
    shouldScrollToBottom,
    scrollToBottom,
    client,
  ]);

  return {
    typingUsers,
    wasDisconnected,
  };
};

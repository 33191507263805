import React from "react";
import { Check } from "lucide-react";
import { MessageStatus } from "../../models/message";

interface ReadStatusProps {
  status?: MessageStatus;
  readBy?: Array<{ userId: string }> | null;
  isOutgoing: boolean;
}

export const ReadStatus: React.FC<ReadStatusProps> = ({
  status,
  readBy,
  isOutgoing,
}) => {
  // Only show on outgoing messages
  if (!isOutgoing) return null;

  // Default to empty array if undefined/null
  const safeReadBy = readBy || [];
  const safeStatus = status || "sent";

  // Has been read by someone
  const isRead = safeReadBy.length > 0;

  // Is delivered but not read
  const isDelivered = safeStatus === "sent" && safeReadBy.length === 0;

  // Is still sending
  const isSending = safeStatus === "sending";

  const tooltipText = isRead
    ? `Letto${safeReadBy.length > 1 ? ` da ${safeReadBy.length} persone` : ""}`
    : isDelivered
    ? "Consegnato"
    : isSending
    ? "In invio"
    : "Stato sconosciuto";

  return (
    <div className="flex items-center justify-end mt-1 mr-1">
      {isRead ? (
        // Blue double checkmark - READ
        <div className="flex text-blue-600 drop-shadow-sm">
          <Check className="h-3 w-3 stroke-[2.5px]" />
          <Check className="h-3 w-3 -ml-1 stroke-[2.5px]" />
          <div className="absolute bottom-full right-0 mb-1 hidden group-hover:block">
            <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded shadow-md whitespace-nowrap">
              {tooltipText}
            </div>
            <div className="absolute -bottom-1 right-1 w-2 h-2 bg-gray-800 transform rotate-45"></div>
          </div>
        </div>
      ) : isDelivered ? (
        // Gray double checkmark - DELIVERED
        <div className="flex text-gray-600 drop-shadow-sm">
          <Check className="h-3 w-3 stroke-[2.5px]" />
          <Check className="h-3 w-3 -ml-1 stroke-[2.5px]" />
          <div className="absolute bottom-full right-0 mb-1 hidden group-hover:block">
            <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded shadow-md whitespace-nowrap">
              {tooltipText}
            </div>
            <div className="absolute -bottom-1 right-1 w-2 h-2 bg-gray-800 transform rotate-45"></div>
          </div>
        </div>
      ) : isSending ? (
        // Gray single checkmark - SENDING
        <div className="text-gray-500 drop-shadow-sm">
          <Check className="h-3 w-3 stroke-[2.5px]" />
          <div className="absolute bottom-full right-0 mb-1 hidden group-hover:block">
            <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded shadow-md whitespace-nowrap">
              {tooltipText}
            </div>
            <div className="absolute -bottom-1 right-1 w-2 h-2 bg-gray-800 transform rotate-45"></div>
          </div>
        </div>
      ) : (
        // Fallback - something went wrong
        <div className="text-gray-500 font-medium">
          <span className="text-xs">?</span>
          <div className="absolute bottom-full right-0 mb-1 hidden group-hover:block">
            <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded shadow-md whitespace-nowrap">
              {tooltipText}
            </div>
            <div className="absolute -bottom-1 right-1 w-2 h-2 bg-gray-800 transform rotate-45"></div>
          </div>
        </div>
      )}
    </div>
  );
};

import axios, { AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN_KEY } from "../consts";
import config from "../config/config";

export interface EnhancedRequestConfig extends AxiosRequestConfig {
  onProgress?: (progress: number) => void;
}

const restClient = axios.create({
  baseURL: config.api.rest,
  headers: {
    "Content-Type": "application/json",
  },
});

restClient.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem(ACCESS_TOKEN_KEY) ||
      sessionStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

restClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // TODO: Check if this is the right way to hanlde it (in the GraphQL client it wasn't like this)
      console.error("[REST call] Unauthorized error:", error);
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const enhancedClient = {
  // Original methods remain for backward compatibility
  ...restClient,

  // Enhanced post with progress tracking
  postWithProgress: async (
    url: string,
    data: any,
    config: EnhancedRequestConfig = {}
  ) => {
    const { onProgress, ...axiosConfig } = config;

    if (data instanceof FormData) {
      // Set headers properly - this is the critical fix
      axiosConfig.headers = {
        ...axiosConfig.headers,
        'Content-Type': 'multipart/form-data', // Let browser set the boundary parameter
      };
    }

    if (onProgress) {
      console.log("Setting up progress handler for:", url);
      
      axiosConfig.onUploadProgress = (progressEvent) => {
        console.log("Raw progress event:", {
          loaded: progressEvent.loaded,
          total: progressEvent.total
        });
        
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Progress update: ${percentCompleted}%`);
          onProgress(percentCompleted);
        }
      };
    }

    return restClient.post(url, data, axiosConfig);
  },
  post: (url: string, data: any, config: AxiosRequestConfig = {}) => {
    // Important: For FormData, remove content-type to let browser set it with boundary
    if (data instanceof FormData) {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
    }

    return restClient.post(url, data, config);
  },
};

export default enhancedClient;

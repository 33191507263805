import CookieConsent from "react-cookie-consent";

export const CookieConsentBanner = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accetta"
    declineButtonText="Rifiuta"
    enableDeclineButton
    onAccept={() => {}}
    onDecline={() => {}}
    style={{ background: "#2B373B" }}
    buttonStyle={{
      color: "#4e503b",
      fontSize: "13px",
    }}
    declineButtonStyle={{
      color: "#fff",
      background: "#f44336",
      fontSize: "13px",
    }}
  >
    Questo sito utilizza i cookies per assicurare all’utente la migliore
    esperienza possibile. Cliccando sul tasto 'Accetta' o continuando nella
    navigazione acconsenti all'uso dei cookies secondo la nostra cookie policy.
    Per maggiori informazioni puoi consultare la{" "}
    <a
      href="/cookie-policy"
      className="text-blue-500 hover:text-blue-700 font-semibold underline hover:underline"
    >
      Cookie Policy.
    </a>
  </CookieConsent>
);

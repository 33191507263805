import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "./App";
import { UserCacheProvider } from "./context/UserCacheContext";
import "./index.css";
//import reportWebVitals from "./reportWebVitals";
import graphQLClient from "./services/gqlClients";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { IOSNotificationProvider } from "./hooks/useIOSNotification";

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const tagManagerArgs = {
  gtmId: "GTM-N9Q5LS54",
};

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV === "production") {
  //console.log = function () {}; // Disable console.log in production
}

if ("serviceWorker" in navigator) {
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log("Service Worker registration successful");
    },
    onError: (error) => {
      console.error("Service Worker registration failed:", error);
    },
  });
}

window.addEventListener('beforeunload', () => {
  console.log("Unloading window");
  window.isUnloading = true;
});


// Only use StrictMode in development for better debugging
if (process.env.NODE_ENV === "development") {
  root.render(
    <React.StrictMode>
      <ApolloProvider client={graphQLClient}>
        <IOSNotificationProvider>
          <UserCacheProvider>
            <App />
          </UserCacheProvider>
        </IOSNotificationProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
} else {
  // In production, no StrictMode to prevent double-mounting
  root.render(
    <ApolloProvider client={graphQLClient}>
      <IOSNotificationProvider>
        <UserCacheProvider>
          <App />
        </UserCacheProvider>
      </IOSNotificationProvider>
    </ApolloProvider>
  );
}

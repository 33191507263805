import { gql } from '@apollo/client';
import { CHAT_FIELDS_FRAGMENT, MESSAGE_FIELDS_FRAGMENT } from './fragments';


export const CHATS_QUERY = gql`
  query Chats($cursor: String, $limit: Int, $search: String) {
    chats(
      pagination: { cursor: $cursor, limit: $limit },
      filter: { search: $search }
    ) {
      __typename
      totalCount
      edges {
        __typename
        cursor
        node {
          ...ChatFields
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${CHAT_FIELDS_FRAGMENT}
`;

export const CHAT_QUERY = gql`
  query Chat($chatId: ID!) {
    chat(id: $chatId) {
      ...ChatFields
    }
  }
  ${CHAT_FIELDS_FRAGMENT}
`;

export const MESSAGES_QUERY = gql`
  query Messages($chatId: ID!, $cursor: String, $limit: Int, $sortDirection: SortDirection) {
    messages(chatId: $chatId, pagination: { cursor: $cursor, limit: $limit, sortDirection: $sortDirection }) {
      __typename
      totalCount
      edges {
        __typename
        cursor
        node {
          ...MessageFields
        }
      }
      pageInfo {
        __typename
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${MESSAGE_FIELDS_FRAGMENT}
`;


export const MESSAGE_QUERY = gql`
  query Message($chatId: ID!, $messageId: ID!) {
    message(chatId: $chatId, messageId: $messageId) {
      ...MessageFields
    }
  }
  ${MESSAGE_FIELDS_FRAGMENT}
`;

export const ONLINE_USERS_QUERY = gql`
  query OnlineUsers {
      onlineUsers {
          userId
          displayName
          avatarUrl
          joinedAt
          role
      }
  }
`;
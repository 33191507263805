const config = {
  env: process.env.REACT_APP_ENV || "production",
  domain: process.env.REACT_APP_DOMAIN || "localhost",
  
  api: {
    graphql: process.env.REACT_APP_GRAPHQL_API_URL,
    rest: process.env.REACT_APP_REST_API_URL,
    websocket: process.env.REACT_APP_WS_URL,
  },
  google: {
    mapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  stripe: {
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    secretKey: process.env.REACT_APP_STRIPE_SECRET_KEY,
  },
  firebase: {
    vapidKey: process.env.REACT_APP_VAPID_KEY,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },

};

export default config;

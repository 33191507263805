import React, { createContext, useContext, useEffect, useState } from 'react';
import { isIos, isInStandaloneMode } from '../services/mobileUtils';

interface iOSNotificationContextType {
  needsInstallation: boolean;
}

const iOSNotificationContext = createContext<iOSNotificationContextType>({ needsInstallation: false });

export const IOSNotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [needsInstallation, setNeedsInstallation] = useState(false);

  useEffect(() => {
    setNeedsInstallation(isIos() && !isInStandaloneMode());
  }, []);

  return (
    <iOSNotificationContext.Provider value={{ needsInstallation }}>
      {children}
    </iOSNotificationContext.Provider>
  );
};

export const useIOSNotification = () => useContext(iOSNotificationContext);
import React, { useEffect, useRef, useState } from "react";
import { Search, Users, X } from "lucide-react";
import { Chat } from "../models/chat";
import { useQuery } from "@apollo/client";
import { ChatsResponse } from "../models/chatResponse";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { CHATS_QUERY, ONLINE_USERS_QUERY } from "../api/queries";
import {
  kMessageTypeUserPresence,
  WebSocketMessage,
} from "../context/WebSocketMessage";
import useDebounce from "../../../hooks/useDebounce";
import { useActiveChat } from "../../../context/ActiveChatContext";

interface ChatsListProps {
  onSelectChat: (chat: Chat) => void;
  ws: any;
  currentUserId?: string;
}

const ITEMS_PER_PAGE = 20;
const ChatsList: React.FC<ChatsListProps> = ({
  onSelectChat,
  ws,
  currentUserId,
}) => {
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, 300);
  const [onlineUsers, setOnlineUsers] = useState<{ [userId: string]: boolean }>(
    {}
  );
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { activeChat } = useActiveChat();

  const { data, loading, fetchMore, refetch } = useQuery<ChatsResponse>(
    CHATS_QUERY,
    {
      variables: { limit: ITEMS_PER_PAGE, search: debouncedFilter },
    }
  );

  const { data: onlineUsersData } = useQuery(ONLINE_USERS_QUERY);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (data?.chats.edges.length && !loading) {
        const lastCursor = data.chats.edges[data.chats.edges.length - 1].cursor;
        fetchMore({
          variables: {
            cursor: lastCursor,
            limit: ITEMS_PER_PAGE,
            search: debouncedFilter,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              chats: {
                ...prev.chats, // Spread previous chats first
                totalCount: fetchMoreResult.chats.totalCount, // Optionally update totalCount if needed
                edges: [...prev.chats.edges, ...fetchMoreResult.chats.edges],
                __typename: prev.chats.__typename,
              },
            };
          },
        });
      }
    },
    enabled: !loading,
  });

  useEffect(() => {
    if (onlineUsersData) {
      const onlineUsersMap = onlineUsersData.onlineUsers.reduce(
        (acc: { [userId: string]: boolean }, user: { userId: string }) => {
          acc[user.userId] = true;
          return acc;
        },
        {}
      );
      setOnlineUsers(onlineUsersMap);
    }
  }, [onlineUsersData]);

  useEffect(() => {
    const handleMessage = (message: WebSocketMessage) => {
      const { type, data } = message;

      if (type === kMessageTypeUserPresence) {
        const { userId, status } = data;
        setOnlineUsers((prev) => ({ ...prev, [userId]: status === "online" })); // NOTE: If we'll define more statuses we should use a more complex object
      }
    };

    ws.onMessage(handleMessage);

    return () => {
      ws.removeMessageHandler(handleMessage);
    };
  }, [ws]);

  useEffect(() => {
    refetch({ search: debouncedFilter });
  }, [debouncedFilter, refetch]);

  const getCounterpartUserId = (
    chat: Chat,
    currentUserId?: string
  ): string | null => {
    const counterpart = chat.participants.find(
      (participant) => participant.userId !== currentUserId
    );
    return counterpart ? counterpart.userId : null;
  };

  const clearFilter = () => {
    setFilter("");
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
      <div className="w-70 h-full border-r border-gray-200 flex flex-col bg-white">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <input
              type="text"
              placeholder="Cerca conversazione..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
            {filter && (
              <button
                className="absolute right-3 top-2.5 text-gray-400"
                onClick={clearFilter}
              >
                <X size={20} />
              </button>
            )}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {data?.chats.edges.map(({ node: chat }) => (
            <div
              key={chat.id}
              className={`p-4 hover:bg-gray-50 cursor-pointer flex items-center border-b border-gray-100 
                ${activeChat && activeChat.id === chat.id ? "bg-blue-50" : ""}`} // Add highlight for active chat
              onClick={() => {
                onSelectChat(chat);
              }}
            >
              <div className="relative">
                <img
                  src={
                    chat.settings.image || process.env.PUBLIC_URL + "/logo.png"
                  }
                  alt={chat.settings.title || ""}
                  className="w-10 h-10 rounded-full"
                />
                {chat.type === "DIRECT" &&
                  (() => {
                    const counterpartUserId = getCounterpartUserId(
                      chat,
                      currentUserId
                    );
                    return counterpartUserId &&
                      onlineUsers[counterpartUserId] ? (
                      <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                    ) : null;
                  })()}
                {chat.type === "GROUP" && (
                  <div className="absolute bottom-0 right-0 bg-gray-100 rounded-full p-1">
                    <Users size={12} className="text-gray-600" />
                  </div>
                )}
              </div>

              <div className="ml-3 flex-1 min-w-0">
                <div className="flex justify-between items-start">
                  <h3 className="font-medium text-gray-900 truncate">
                    {chat.settings.title}
                  </h3>
                  <span className="text-xs text-gray-500 whitespace-nowrap ml-2">
                    {chat.lastMessage?.sentAt
                      ? new Date(chat.lastMessage.sentAt).toLocaleString()
                      : ""}
                  </span>
                </div>
                <div className="flex justify-between items-center mt-1">
                  <p className="text-sm text-gray-500 truncate">
                    {chat.lastMessage?.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div ref={loadMoreRef} />
          {loading && <div className="p-4 text-center">Loading...</div>}
        </div>

        {/* NOTE: Add later create new chat for user  */}
        {/* <div className="p-4 border-t border-gray-200">
          <Menu as="div" className="relative w-full">
            <Menu.Button className="w-full flex items-center justify-center space-x-2 p-2 border rounded-lg hover:bg-gray-50 text-gray-700 transition-colors">
              <User size={20} />
              <span>Nuova chat</span>
              <ChevronDown size={20} />
            </Menu.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <MenuItems className="absolute bottom-full left-0 right-0 mb-2 w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100" : ""
                        } flex w-full items-center px-4 py-3 text-sm`}
                      >
                        <User className="mr-3 h-5 w-5" />
                        Chat diretta
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100" : ""
                        } flex w-full items-center px-4 py-3 text-sm`}
                      >
                        <Users className="mr-3 h-5 w-5" />
                        Nuovo gruppo
                      </button>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div> */}
      </div>
    </div>
  );
};

export default ChatsList;

import { getSharedValue, setSharedValue, deleteSharedValue } from './sharedStore';

export interface LaunchState {
  source: "notification" | "direct" | "normal";
  chatId: string | null;
  messageId: string | null;
  timestamp: number;
}

// Constants for cache keys
const SESSION_STORAGE_KEY = "app_launch_state";
const CACHE_STORAGE_KEY = "app_launch_state_cache";

/**
 * LaunchStateManager handles application launch states across various contexts
 * such as notification clicks, deep links, and normal app launches.
 *
 * This is particularly important for mobile platforms like iOS where PWAs have
 * unique lifecycle behaviors when launched from notifications.
 */
class LaunchStateManager {
  private readonly STORAGE_KEY = SESSION_STORAGE_KEY;

  /**
   * Save launch state to both sessionStorage and Cache API
   * This dual approach ensures it works across all platforms including iOS
   */
  async saveLaunchState(state: LaunchState): Promise<void> {
    try {
      // Save to session storage (primary storage)
      sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
      
      // Also save to Cache API for iOS PWA reliability
      await setSharedValue(CACHE_STORAGE_KEY, JSON.stringify(state));
      console.log('Launch state saved to both session storage and Cache API');
    } catch (err) {
      console.error("Failed to save launch state:", err);
    }
  }

  /**
   * Get current launch state from sessionStorage first, fallback to Cache API
   */
  async getLaunchStateAsync(): Promise<LaunchState | null> {
    try {
      // Try session storage first
      const stateStr = sessionStorage.getItem(this.STORAGE_KEY);
      if (stateStr) {
        return JSON.parse(stateStr) as LaunchState;
      }
      
      // Fallback to Cache API (important for iOS)
      const cachedState = await getSharedValue(CACHE_STORAGE_KEY);
      if (cachedState) {
        console.log('Retrieved launch state from Cache API fallback');
        return JSON.parse(cachedState) as LaunchState;
      }
      
      return null;
    } catch (err) {
      console.error("Failed to get launch state:", err);
      return null;
    }
  }
  
  /**
   * Get current launch state (synchronous, for backward compatibility)
   */
  getLaunchState(): LaunchState | null {
    try {
      const stateStr = sessionStorage.getItem(this.STORAGE_KEY);
      if (!stateStr) return null;
      
      return JSON.parse(stateStr) as LaunchState;
    } catch (err) {
      console.error("Failed to parse launch state:", err);
      return null;
    }
  }

  /**
   * Consume launch state asynchronously (get and clear)
   */
  async consumeLaunchStateAsync(): Promise<LaunchState | null> {
    const state = await this.getLaunchStateAsync();
    await this.clearLaunchStateAsync();
    return state;
  }

  /**
   * Consume launch state (get and clear) - synchronous version
   */
  consumeLaunchState(): LaunchState | null {
    const state = this.getLaunchState();
    this.clearLaunchState();
    return state;
  }

  /**
   * Clear launch state from both storage mechanisms
   */
  async clearLaunchStateAsync(): Promise<void> {
    sessionStorage.removeItem(this.STORAGE_KEY);
    await deleteSharedValue(CACHE_STORAGE_KEY);
  }
  
  /**
   * Clear launch state (synchronous version)
   */
  clearLaunchState(): void {
    sessionStorage.removeItem(this.STORAGE_KEY);
    // Note: We can't delete from Cache API synchronously,
    // but that's ok since it's just a fallback
  }

  /**
   * Check if launch state is fresh (within specified time window)
   */
  isLaunchStateFresh(maxAgeMs: number = 30000): boolean {
    const state = this.getLaunchState();
    if (!state) return false;

    return Date.now() - state.timestamp < maxAgeMs;
  }
}

export const launchStateManager = new LaunchStateManager();
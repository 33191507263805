import React from "react";
import { NavigationItem } from "../consts";

interface NavigationLinksProps {
  items: NavigationItem[];
  isAuthenticated: boolean;
  hasRole: (role: string) => boolean;
  linkClassName: string;
}

const NavigationLinks: React.FC<NavigationLinksProps> = ({
  items,
  isAuthenticated,
  hasRole,
  linkClassName,
}) => {
  return (
    <>
      {items.map((item) => {
        // Check if the item should be displayed
        const shouldShow =
          item.public ||
          (isAuthenticated &&
            (!item.roles || item.roles.some((role) => hasRole(role))));

        if (!shouldShow) return null;

        return (
          <a
            key={typeof item.name === 'string' ? item.name : item.href}
            href={item.href}
            className={linkClassName}
          >
            {item.name}
          </a>
        );
      })}
    </>
  );
};

export default NavigationLinks;
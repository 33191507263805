import Footer from "./Footer";
import { useActiveChat } from "../context/ActiveChatContext";
import Header from "./Header";
import { useEffect, useState } from "react";

export default function PublicLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { activeChat } = useActiveChat(); // Add this line to get active chat state
  const [isMobile, setIsMobile] = useState(false);

  // Check if viewport is mobile-sized
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // 768px is Tailwind's md breakpoint
    };

    // Check initially
    checkMobile();

    // Add resize listener
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Only hide header/footer when both conditions are true:
  // 1. There is an active chat
  // 2. We're on mobile
  const shouldHideHeaderFooter = activeChat && isMobile;
  
  return (
    <div className="flex flex-col min-h-screen">
      {!shouldHideHeaderFooter && <Header />}
      <main className="flex-1 py-2">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
      {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
}

import React, { forwardRef } from "react";
import { Message } from "../../models/message";
import { ChatParticipant } from "../../models/chatParticipant";
import { Image, RefreshCw } from "lucide-react";
import { useChatImageUpload } from "../../hooks/useChatImageUpload";
import { useFileUploadContext } from "../../../../hooks/FileUploadContext";
import { MessageBubbleWrapper } from "./MessageBubbleWrapper";

interface FailedImageBubbleProps {
  message: Message;
  chatId: string;
  currentUserId: string;
  isMine: boolean;
  participants: ChatParticipant[]; // Add this for MessageBubbleWrapper
}

const FailedImageBubble = forwardRef<HTMLDivElement, FailedImageBubbleProps>(
  ({ message, chatId, currentUserId, isMine, participants }, ref) => {
    const { uploads } = useFileUploadContext();
    const { retryFailedUpload } = useChatImageUpload(chatId, currentUserId);

    const failedUpload = Object.values(uploads).find(
      (upload) => upload.id === message.id && upload.status === "error"
    );

    const handleRetry = async () => {
      if (failedUpload) {
        await retryFailedUpload(message.id, failedUpload.file);
      } else {
        console.error("Cannot retry upload: original file not found");
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async (e: any) => {
          const file = e.target.files?.[0];
          if (file) {
            await retryFailedUpload(message.id, file);
          }
        };
        input.click();
      }
    };

    return (
      <MessageBubbleWrapper
        ref={ref}
        message={message}
        currentUserId={currentUserId}
        participants={participants}
        className="p-3 bg-red-50"
      >
        <div className="flex flex-col">
          <div className="flex items-center space-x-2 mb-2">
            <Image size={24} />
            <span className="text-red-800">Upload failed</span>
          </div>

          {failedUpload?.previewUrl && (
            <div className="mb-2 rounded overflow-hidden">
              <img
                src={failedUpload.previewUrl}
                alt="Failed upload preview"
                className="w-full max-h-32 object-cover opacity-50"
              />
            </div>
          )}

          <button
            onClick={handleRetry}
            className="flex items-center space-x-1 text-sm font-medium text-red-600 hover:text-red-800"
          >
            <RefreshCw size={14} />
            <span>Retry upload</span>
          </button>
        </div>
      </MessageBubbleWrapper>
    );
  }
);

FailedImageBubble.displayName = 'FailedImageBubble';

export default FailedImageBubble;
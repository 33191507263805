import React, { useState } from "react";
import { useFirebaseMessaging } from "../hooks/useFirebaseMessaging";
import { useToast } from "../context/ToastContext";
import { isInStandaloneMode, isIos } from "../services/mobileUtils";
import { useAuth } from "../features/auth/hooks/useAuth";
import { userRoleAdmin } from "../consts";
import { useActiveChat } from "../context/ActiveChatContext";

export const FirebaseNotificationWrapper: React.FC = () => {
  const { isAuthenticated, hasRole, identityId } = useAuth();
  const { notification, requestPushPermission } = useFirebaseMessaging();
  const { showToast } = useToast();
  const prevNotificationRef = React.useRef<any>(null);
  const [showPrompt, setShowPrompt] = useState(true);
  const { isActiveChatId } = useActiveChat(); 
  
  // Don't show notification banner on iOS unless in standalone mode
  // Also make it visible only to admins (for testing purposes)
  const shouldShowBanner =
    (!isIos() || (isIos() && isInStandaloneMode())) &&
    typeof window !== "undefined" &&
    "Notification" in window &&
    Notification.permission !== "granted" &&
    isAuthenticated &&
    hasRole(userRoleAdmin);

    const shouldShowNotification = React.useCallback((notification: any) => {
      // First check if notification is relevant
      // Don't show if I'm the sender
      if (notification.identityId === identityId) {
        return false;
      }
      
      // Don't show if it's for the active chat
      if (notification.chatId && isActiveChatId(notification.chatId)) {
        return false;
      }
      
      // Do show in all other cases (when notification isn't from me and isn't for active chat)
      return true;
    }, [identityId, isActiveChatId]);


  React.useEffect(() => {
    if (
      notification &&
      notification !== prevNotificationRef.current &&
      shouldShowNotification(notification)
    ) {
      prevNotificationRef.current = notification;
      showToast(
        <div className="flex items-start space-x-3">
          {notification.image && (
            <img
              src={notification.image}
              alt=""
              className="w-12 h-12 rounded-xl object-cover"
            />
          )}
          <div className="flex-1 min-w-0">
            <h2 className="font-semibold text-[15px] leading-5 text-black">
              {notification.title}
            </h2>
            <p className="text-[13px] leading-4 text-gray-600 mt-0.5">
              {notification.body}
            </p>
          </div>
        </div>
      );
    }
  }, [notification, showToast, identityId, shouldShowNotification]);

  if (!showPrompt || !shouldShowBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-blue-500 text-white p-4 text-center z-40">
      <p>
        Attiva le notifiche per restare in contatto con gli organizzatori e gli
        altri partecipanti degli eventi.
      </p>
      <div className="flex justify-center gap-4 pb-[env(safe-area-inset-bottom)]">
        <button
          onClick={async () => {
            const granted = await requestPushPermission();
            console.log("granted", granted);
            setShowPrompt(false);
          }}
          className="inline-flex items-center gap-2 bg-white text-blue-500 px-4 py-2 rounded-full mt-2 active:bg-gray-100"
        >
          Accetto
        </button>
        <button
          onClick={() => setShowPrompt(false)}
          className="inline-flex items-center gap-2 bg-white text-blue-500 px-4 py-2 rounded-full mt-2 active:bg-gray-100"
        >
          Più tardi
        </button>
      </div>
    </div>
  );
};

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SignIn from "./features/auth/pages/SignIn";
import Signup from "./features/auth/pages/Signup";
import AuthLayout from "./layouts/AuthLayout";
import Landing from "./features/public/pages/Landing";
import PublicLayout from "./layouts/PublicLayout";
import NewEvent from "./features/events/pages/NewEvent";
import Event from "./features/events/pages/Event";
import Calendar from "./features/events/pages/Calendar";
import Events from "./features/events/pages/Events";
import { AuthProvider } from "./features/auth/hooks/useAuth";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "./consts";
import {
  ACCOUNT_ROUTE,
  CALENDAR_ROUTE,
  CHECKOUT_ROUTE,
  CONFIRM_MAIL_ROUTE,
  CHATS_ROUTE,
  COOKIE_POLICY_ROUTE,
  CREATE_EVENT_ROUTE,
  DONATION_ROUTE,
  EVENTS_ROUTE,
  FAQ_ROUTE,
  FORGOT_PWD_ROUTE,
  LANDING_ROUTE,
  LOGIN_ROUTE,
  MY_EVENTS_ROUTE,
  PASSWORD_RECOVERY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PROFILE_ROUTE,
  ProtectedRoute,
  SIGNOUT_ROUTE,
  SIGNUP_ROUTE,
  TERMS_ROUTE,
  UPDATE_EVENT_ROUTE,
  WELCOME_ROUTE,
} from "./routes";

import Welcome from "./features/auth/pages/Welcome";
import Logout from "./features/auth/pages/Logout";
import Profile from "./features/user/pages/Profile";
import Checkout from "./features/events/pages/Checkout";
import ConfirmEmail from "./features/public/pages/ConfirmEmail";
import Donations from "./features/public/pages/Donations";
import CookiePolicy from "./features/public/pages/CookiePolicy";
import PrivacyPolicy from "./features/public/pages/PrivacyPolicy";
import PasswordRecovery from "./features/auth/pages/PasswordReset";
import ForgotPassword from "./features/auth/pages/ForgotPassoword";
import Page404 from "./pages/404";
import MyEvents from "./features/events/pages/MyEvents";
import ChatLayout from "./features/chat/pages/ChatLayout";
import FAQ from "./features/public/pages/FAQ";
import TermsOfUse from "./features/public/pages/Terms";
import { ToastProvider } from "./context/ToastContext";
import { CookieConsentBanner } from "./pages/CookieConsentBanner";
import Account from "./features/user/pages/Account";
import { IOSInstallationBanner } from "./components/iOSInstallationBanner";
import { FirebaseNotificationWrapper } from "./components/firebaseNotificationWrapper";
import { WebSocketProvider } from "./features/chat/context/WebSocketProvider";
import { FileUploadProvider } from "./hooks/FileUploadContext";
import { ActiveChatProvider } from "./context/ActiveChatContext";
import { AppStateManager } from "./components/AppStateManager";

const App: React.FC = () => {
  return (
    <AuthProvider
      accessTokenKey={ACCESS_TOKEN_KEY}
      refreshTokenKey={REFRESH_TOKEN_KEY}
    >
      <AppStateManager />
      <ToastProvider>
        <IOSInstallationBanner />
        <WebSocketProvider>
          <FileUploadProvider>
            <Router>
              <ActiveChatProvider>
                <Routes>
                  <Route
                    path={LANDING_ROUTE}
                    element={
                      <PublicLayout>
                        <Landing />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path={WELCOME_ROUTE}
                    element={
                      <PublicLayout>
                        <Welcome />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path={COOKIE_POLICY_ROUTE}
                    element={
                      <PublicLayout>
                        <CookiePolicy />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path={FAQ_ROUTE}
                    element={
                      <PublicLayout>
                        <FAQ />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path={PRIVACY_POLICY_ROUTE}
                    element={
                      <PublicLayout>
                        <PrivacyPolicy />
                      </PublicLayout>
                    }
                  />

                  <Route
                    path={ACCOUNT_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <Account />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path={TERMS_ROUTE}
                    element={
                      <PublicLayout>
                        <TermsOfUse />
                      </PublicLayout>
                    }
                  />

                  <Route
                    path={CONFIRM_MAIL_ROUTE}
                    element={
                      <PublicLayout>
                        <ConfirmEmail />
                      </PublicLayout>
                    }
                  />

                  <Route
                    path={DONATION_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <Donations />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route path={SIGNOUT_ROUTE} element={<Logout />} />

                  <Route
                    path={LOGIN_ROUTE}
                    element={
                      <AuthLayout>
                        <SignIn />
                      </AuthLayout>
                    }
                  />
                  <Route
                    path={SIGNUP_ROUTE}
                    element={
                      <AuthLayout>
                        <Signup />
                      </AuthLayout>
                    }
                  />

                  <Route
                    path={FORGOT_PWD_ROUTE}
                    element={
                      <AuthLayout>
                        <ForgotPassword />
                      </AuthLayout>
                    }
                  />
                  <Route
                    path={PASSWORD_RECOVERY_ROUTE}
                    element={
                      <AuthLayout>
                        <PasswordRecovery />
                      </AuthLayout>
                    }
                  />

                  <Route
                    path={EVENTS_ROUTE}
                    element={
                      <PublicLayout>
                        <Events />
                      </PublicLayout>
                    }
                  />

                  <Route
                    path={`${EVENTS_ROUTE}/:eventId`}
                    element={
                      <PublicLayout>
                        <Event />
                      </PublicLayout>
                    }
                  />

                  <Route
                    path={CALENDAR_ROUTE}
                    element={
                      <PublicLayout>
                        <Calendar />
                      </PublicLayout>
                    }
                  />
                  <Route
                    path={CREATE_EVENT_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <NewEvent />
                          </PublicLayout>
                        )}
                      />
                    }
                  />
                  <Route
                    path={UPDATE_EVENT_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <NewEvent />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path={PROFILE_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <Profile />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path={CHATS_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <ChatLayout />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path={MY_EVENTS_ROUTE}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <MyEvents />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path={`${CHECKOUT_ROUTE}/:eventId`}
                    element={
                      <ProtectedRoute
                        component={() => (
                          <PublicLayout>
                            <Checkout />
                          </PublicLayout>
                        )}
                      />
                    }
                  />

                  <Route
                    path="/"
                    element={<Navigate to={LANDING_ROUTE} replace />}
                  />

                  {/* 404 Not Found Route */}
                  <Route
                    path="*"
                    element={
                      <PublicLayout>
                        <Page404 />
                      </PublicLayout>
                    }
                  />
                </Routes>

                {/* Cookie Consent Banner */}
                <CookieConsentBanner />

                <FirebaseNotificationWrapper />
              </ActiveChatProvider>
            </Router>
          </FileUploadProvider>
        </WebSocketProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;

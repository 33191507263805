import { useCallback, useEffect, useState } from "react";
import { Message } from "../models/message";
import {  NetworkStatus, useApolloClient, useQuery } from "@apollo/client";
import { MessagesResponse } from "../models/messageResponse";
import { MESSAGES_QUERY } from "../api/queries";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";

const ITEMS_PER_PAGE = 20;

export const useChatMessages = (chatId: string, currentUserId: string) => {
  const client = useApolloClient();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [pendingMarkAsRead, setPendingMarkAsRead] = useState<Set<string>>(
    new Set()
  );

  const { data, loading, fetchMore, refetch, networkStatus } = useQuery<MessagesResponse>(
    MESSAGES_QUERY,
    {
      variables: {
        chatId,
        limit: ITEMS_PER_PAGE,
        sortDirection: "DESC",
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    // NetworkStatus.ready = 7 (complete, including empty results)
    if (networkStatus === NetworkStatus.ready || networkStatus === NetworkStatus.error) {
      setInitialLoadComplete(true);
    }
    
    // Reset on chat change
    return () => setInitialLoadComplete(false);
  }, [networkStatus, chatId]);
  
  // Add a new message to the cache
  const addMessage = useCallback(
    (newMessage: Message) => {
      if (!newMessage.__typename) {
        newMessage.__typename = "Message";
      }

      const newMessageRef = client.cache.writeFragment({
        data: newMessage,
        fragment: MESSAGE_FIELDS_FRAGMENT,
      });

      client.cache.modify({
        fields: {
          messages(existingMessages: any = {}, { storeFieldName, readField }) {
            const storeFieldNameMatch =
              storeFieldName.match(/"chatId":"([^"]+)"/);
            const storeFieldChatId = storeFieldNameMatch
              ? storeFieldNameMatch[1]
              : null;

            // Only update if this is for our current chat
            if (storeFieldChatId !== chatId) {
              return existingMessages;
            }

            // Check if message already exists to prevent duplicates
            if (
              existingMessages.edges?.some(
                (edge: any) => readField("id", edge.node) === newMessage.id
              )
            ) {
              return existingMessages;
            }

            // Create proper edge structure
            const newEdge = {
              __typename: "MessageEdge",
              cursor: newMessage.id,
              node: newMessageRef,
            };

            return {
              __typename: "MessagesConnection",
              ...existingMessages,
              totalCount: (existingMessages.totalCount || 0) + 1,
              edges: [newEdge, ...(existingMessages.edges || [])],
            };
          },
        },
      });

      // Also update the last message in the chat
      client.cache.modify({
        id: `Chat:${chatId}`,
        fields: {
          lastMessage: () => newMessage,
        },
      });
    },
    [client.cache, chatId]
  );

  // Update read status for multiple messages
  const updateMessageReadStatus = useCallback(
    (messageIds: string[], userId: string) => {
      client.cache.modify({
        fields: {
          messages(existingMessages = {}, { storeFieldName, readField }) {
            const storeFieldNameMatch =
              storeFieldName.match(/"chatId":"([^"]+)"/);
            const storeFieldChatId = storeFieldNameMatch
              ? storeFieldNameMatch[1]
              : null;

            // Only update if this is for our current chat
            if (storeFieldChatId !== chatId) {
              return existingMessages;
            }

            if (!existingMessages.edges || !existingMessages.edges.length) {
              return existingMessages;
            }

            // Create a new array of edges with updated read status
            const newEdges = existingMessages.edges.map(
              (edge: { node: any }) => {
                const messageId = readField("id", edge.node);

                if (
                  typeof messageId === "string" &&
                  messageIds.includes(messageId)
                ) {
                  // Get existing readBy array
                  const existingReadBy = readField("readBy", edge.node) || [];

                  // Check if user already marked as read
                  const userAlreadyRead =
                    Array.isArray(existingReadBy) &&
                    existingReadBy.some(
                      (rb: any) => readField("userId", rb) === userId
                    );

                  if (userAlreadyRead) {
                    return edge;
                  }

                  // Create new readBy entry
                  const newReadStatus = {
                    __typename: "ReadStatus",
                    userId: userId,
                    readAt: new Date().toISOString(),
                  };

                  // Get all existing fields
                  const id = readField("id", edge.node);
                  const content = readField("content", edge.node);
                  const createdAt = readField("createdAt", edge.node);
                  const updatedAt = readField("updatedAt", edge.node);
                  const status = readField("status", edge.node);
                  const reactions = readField("reactions", edge.node);
                  const replyTo = readField("replyTo", edge.node);
                  const senderId = readField("senderId", edge.node);

                  // Create a new node with updated readBy array
                  const newNode = client.cache.writeFragment({
                    id: client.cache.identify(edge.node),
                    fragment: MESSAGE_FIELDS_FRAGMENT,
                    data: {
                      id,
                      chatId,
                      senderId,
                      content,
                      createdAt,
                      updatedAt,
                      status,
                      reactions,
                      replyTo,
                      __typename: "Message",
                      readBy: Array.isArray(existingReadBy)
                        ? [...existingReadBy, newReadStatus]
                        : [newReadStatus],
                    },
                  });

                  return { ...edge, node: newNode };
                }

                return edge;
              }
            );

            return {
              ...existingMessages,
              edges: newEdges,
            };
          },
        },
      });
    },
    [client.cache, chatId]
  );

  // Mark an individual message as read (used by visibility observer)
  const markMessageAsRead = useCallback(
    (messageId: string) => {
      // Only proceed if we have the current user ID
      if (!currentUserId) return;

      // Skip if already pending
      if (pendingMarkAsRead.has(messageId)) return;

      // Add to pending set
      setPendingMarkAsRead((prev) => {
        const newSet = new Set(prev);
        newSet.add(messageId);
        return newSet;
      });

      // Batch read receipts with a slight delay
      setTimeout(() => {
        setPendingMarkAsRead((prev) => {
          const messageIds = Array.from(prev);
          if (messageIds.length > 0) {
            updateMessageReadStatus(messageIds, currentUserId);
          }
          return new Set();
        });
      }, 500);
    },
    [updateMessageReadStatus, pendingMarkAsRead, currentUserId]
  );

  return {
    messages: data?.messages?.edges || [],
    loading: loading && !initialLoadComplete,
    fetchMore,
    refetch,
    hasMoreMessages: data?.messages?.pageInfo?.hasPreviousPage || false,
    addMessage,
    updateMessageReadStatus,
    markMessageAsRead,
  };
};

import {  useEffect, useCallback, useRef } from 'react';
import { ConnectionStatus } from "../context/WebSocketContext";

type WebSocketContextType = {
  connectionStatus: ConnectionStatus;
  sendTyping: (chatId: string, isTyping: boolean) => void;
};

interface UseTypingIndicatorProps {
  chatId: string;
  isConnected: boolean;
  websocket: WebSocketContextType | null;
}

export const useTypingIndicator = ({ 
  chatId, 
  isConnected, 
  websocket 
}: UseTypingIndicatorProps) => {
  // State tracking
  const isTypingRef = useRef(false);
  const typingInChatRef = useRef<string | null>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const keepAliveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastTypingEventTime = useRef<number>(0);
  
  // Clean up any existing typing timers
  const clearTimers = useCallback(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = null;
    }
    if (keepAliveTimeoutRef.current) {
      clearTimeout(keepAliveTimeoutRef.current);
      keepAliveTimeoutRef.current = null;
    }
  }, []);
  
  // Send typing status update
  const sendTypingIndicator = useCallback((isTyping: boolean) => {
    if (!isConnected || !websocket || !chatId) return;
    
    const now = Date.now();
    // Only send if state changed or it's been more than 3s (keep-alive)
    if (isTyping !== isTypingRef.current || now - lastTypingEventTime.current > 3000) {
      websocket.sendTyping(chatId, isTyping);
      lastTypingEventTime.current = now;
      isTypingRef.current = isTyping;
      typingInChatRef.current = isTyping ? chatId : null;
      
      // Handle timers
      clearTimers();
      
      if (isTyping) {
        // Set inactivity timeout (stop typing after 3s of no updates)
        typingTimeoutRef.current = setTimeout(() => {
          sendTypingIndicator(false);
          clearTimers();
        }, 3000);
        
        // Set keep-alive timeout (send typing=true every 3s while active)
        keepAliveTimeoutRef.current = setTimeout(() => {
          if (isTypingRef.current && typingInChatRef.current === chatId) {
            sendTypingIndicator(true);
          }
        }, 3000);
      }
    }
  }, [chatId, isConnected, websocket, clearTimers]);
  
  // Handle visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && isTypingRef.current && 
          isConnected && typingInChatRef.current) {
        sendTypingIndicator(false);
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isConnected, sendTypingIndicator]);
  
  // Clean up on chat change or unmount
  const clearTypingIndicators = useCallback(() => {
    if (isTypingRef.current && typingInChatRef.current && 
        typingInChatRef.current !== chatId && websocket) {
      websocket.sendTyping(typingInChatRef.current, false);
    }
    
    isTypingRef.current = false;
    typingInChatRef.current = null;
    clearTimers();
  }, [chatId, websocket, clearTimers]);
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (isTypingRef.current && isConnected && 
          websocket && typingInChatRef.current) {
        websocket.sendTyping(typingInChatRef.current, false);
      }
      clearTimers();
    };
  }, [isConnected, websocket, clearTimers]);
  
  return {
    sendTypingIndicator,
    clearTypingIndicators
  };
};
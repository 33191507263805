import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { ConnectionStatus, WebSocketContext } from "./WebSocketContext";
import {
  kContentMessageTypeImage,
  kContentMessageTypeText,
  kMessageTypeChatJoin,
  kMessageTypeChatLeave,
  kMessageTypeMessage,
  kMessageTypeMessageRead,
  kMessageTypeTyping,
  WebSocketMessage,
} from "./WebSocketMessage";
import config from "../../../config/config";
import useCachedProfile from "../../../hooks/useCachedProfile";
import { useAuth } from "../../auth/hooks/useAuth";
import Cookies from "js-cookie";
import { createLogger } from "../../../utility/logger";
import { ACTIVE_CHAT_KEY } from "../consts";

/**
 * The WebSocketProvider implements a temporary ID system for message synchronization.
 *
 * When a message is sent:
 * 1. A temporary ID is generated client-side (temp-{timestamp}-{random})
 * 2. This ID is included in the message's metadata.tempId
 * 3. The client creates an optimistic UI update using this tempId
 * 4. The server processes the message and assigns a permanent ID
 * 5. The server returns the message with both IDs
 * 6. The useTempMessageHandler hook detects matching tempIds and replaces temporary messages
 *
 * This approach allows for reliable message tracking without adding dependencies
 * between the WebSocket layer and the GraphQL/Apollo cache layer.
 */

const logger = createLogger("WS");

const setAuthCookie = (token: string) => {
  Cookies.set("authToken", token, {
    secure: true,
    sameSite: "strict",
    path: "/",
    domain: config.domain,
  });
};

const globalWebSocketState: {
  instance: WebSocket | null;
  isConnecting: boolean;
  lastConnectAttempt: number;
} = {
  instance: null,
  isConnecting: false,
  lastConnectAttempt: 0,
};

const reconnectionState = {
  lastDisconnectTime: 0,
  reconnectionAttemptTimeout: null as NodeJS.Timeout | null,
  inReconnectionCooldown: false,
  activeConnections: 0,
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.CLOSED
  );
  const messageHandlers = useRef<Set<(message: WebSocketMessage) => void>>(
    new Set()
  );
  const { isAuthenticated, accessToken } = useAuth();
  const { user } = useCachedProfile();
  const reconnectAttempts = useRef(0);
  const reconnectTimer = useRef<NodeJS.Timeout | null>(null);
  const connectRef = useRef<() => void>(() => {});

  const isReconnecting = useRef(false);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const lastPongTimeRef = useRef<number>(0);

  const sendMessage = useCallback(
    (message: any) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
      }
    },
    [socket]
  );

  const connect = useCallback(() => {
    const now = Date.now();
    if (globalWebSocketState.isConnecting) {
      logger.debug("Already reconnecting globally, skipping connect call");
      return;
    }
    // Enforce a strict cooldown period between reconnection attempts
    // This is CRITICAL on mobile - too frequent reconnects can cause browser throttling
    const timeSinceLastDisconnect = now - reconnectionState.lastDisconnectTime;
    if (
      reconnectionState.inReconnectionCooldown ||
      timeSinceLastDisconnect < 2000
    ) {
      logger.debug(
        `In cooldown period (${timeSinceLastDisconnect}ms since last disconnect), deferring connection`
      );

      // Schedule a future reconnection attempt
      if (!reconnectionState.reconnectionAttemptTimeout) {
        reconnectionState.reconnectionAttemptTimeout = setTimeout(() => {
          reconnectionState.inReconnectionCooldown = false;
          reconnectionState.reconnectionAttemptTimeout = null;
          connect();
        }, Math.max(2000 - timeSinceLastDisconnect, 1000));
      }
      return;
    }

    if (now - globalWebSocketState.lastConnectAttempt < 2000) {
      logger.debug("Connect attempt too soon after previous attempt, skipping");
      return;
    }

    if (!isAuthenticated || !user || !accessToken) {
      logger.debug("Not connecting: missing auth credentials");
      return;
    }

    // Use the global socket if it's already connected
    if (globalWebSocketState.instance?.readyState === WebSocket.OPEN) {
      logger.debug("Using existing global WebSocket connection");
      setSocket(globalWebSocketState.instance);
      setConnectionStatus(ConnectionStatus.OPEN);
      return;
    }

    // Close existing socket if any
    if (socket) {
      logger.debug("Closing existing socket before reconnecting");
      try {
        socket.close(1000, "Reconnecting");
      } catch (e) {
        console.error("Error closing socket:", e);
      }
      setSocket(null);
    }

    globalWebSocketState.isConnecting = true;
    globalWebSocketState.lastConnectAttempt = now;

    try {
      setAuthCookie(accessToken);
      const wsUrl = new URL(`${config.api.websocket}/chat/${user.userId}`);
      const ws = new WebSocket(wsUrl);
      setConnectionStatus(ConnectionStatus.CONNECTING);

      ws.onopen = () => {
        console.log("WebSocket Connected");
        setConnectionStatus(ConnectionStatus.OPEN);
        reconnectAttempts.current = 0; // Reset on successful connection
        isReconnecting.current = false;

        // Reset the last pong time on connection
        lastPongTimeRef.current = Date.now();

        // Set up ping interval - only on mobile devices
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
          }

          pingIntervalRef.current = setInterval(() => {
            if (!ws || ws.readyState !== WebSocket.OPEN) return;

            // If no pong received for 30 seconds, reconnect
            if (Date.now() - lastPongTimeRef.current > 30000) {
              console.log("No pong received, reconnecting...");
              try {
                ws.close(1000, "No pong response");
              } catch (e) {
                console.error("Error closing socket:", e);
              }
              connect();
              return;
            }

            // Send a ping message
            try {
              sendMessage({
                type: "ping",
                version: 1,
                source: "web",
                data: { timestamp: Date.now() },
              });
            } catch (e) {
              console.error("Error sending ping:", e);
            }
          }, 15000); // Every 15 seconds
        }

        globalWebSocketState.instance = ws;
        globalWebSocketState.isConnecting = false;
      };

      ws.onclose = (event) => {
        console.log(`WebSocket Closed: ${event.code} ${event.reason}`);
        setConnectionStatus(ConnectionStatus.CLOSED);
        setSocket(null);
        isReconnecting.current = false;

        // Record disconnect time for cooldown period
        reconnectionState.lastDisconnectTime = Date.now();

        // Set cooldown flag to prevent immediate reconnection
        reconnectionState.inReconnectionCooldown = true;

        // Only auto-reconnect if it wasn't a clean closure
        if (event.code !== 1000 && document.visibilityState === "visible") {
          // Schedule reconnect with exponential backoff
          const delay = Math.min(
            2000 + 1000 * Math.pow(1.5, reconnectAttempts.current),
            30000
          );

          logger.debug(
            `Scheduling reconnect in ${delay}ms (attempt ${
              reconnectAttempts.current + 1
            })`
          );

          if (reconnectTimer.current) {
            clearTimeout(reconnectTimer.current);
          }

          reconnectTimer.current = setTimeout(() => {
            reconnectAttempts.current += 1;
            reconnectionState.inReconnectionCooldown = false;
            connectRef.current();
          }, delay);
        }

        globalWebSocketState.instance = null;
        globalWebSocketState.isConnecting = false;
      };

      ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
        setConnectionStatus(ConnectionStatus.ERROR);
        isReconnecting.current = false;
        globalWebSocketState.isConnecting = false;
      };

      ws.onmessage = (event) => {
        // Any message from the server is treated as a pong
        lastPongTimeRef.current = Date.now();
        try {
          const message: WebSocketMessage = JSON.parse(event.data);
          messageHandlers.current.forEach((handler) => handler(message));
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };
      setSocket(ws);
    } catch (error) {
      console.error("Error creating WebSocket:", error);
      isReconnecting.current = false;
      globalWebSocketState.isConnecting = false;
    }
  }, [accessToken, isAuthenticated, sendMessage, socket, user]);

  useEffect(() => {
    connectRef.current = connect;
  }, [connect]);

  useEffect(() => {
    return () => {
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, []);

  const sendJoin = useCallback(
    (chatId: string) => {
      sendMessage({
        type: kMessageTypeChatJoin,
        version: 1,
        source: "web",
        data: { chatId },
      });
    },
    [sendMessage]
  );

  const lastActiveTimeRef = useRef<number>(Date.now());
  // Handle document visibility changes
  useEffect(() => {
    let focusTimeout: NodeJS.Timeout | null = null;

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        logger.debug("Document became visible, checking WebSocket connection");

        const now = Date.now();
        const timeInBackground = now - lastActiveTimeRef.current;
        lastActiveTimeRef.current = now;

        // Only attempt reconnection if we've been visible for at least 500ms
        // This prevents reconnection attempts during rapid tab switching
        if (focusTimeout) clearTimeout(focusTimeout);

        focusTimeout = setTimeout(() => {
          if (
            socket?.readyState === WebSocket.OPEN &&
            timeInBackground > 5000
          ) {
            logger.debug(
              `Tab was inactive for ${
                timeInBackground / 1000
              }s, triggering sync cycle`
            );

            // Simulate a reconnection cycle
            setConnectionStatus(ConnectionStatus.CONNECTING);
            setTimeout(() => {
              if (socket?.readyState === WebSocket.OPEN) {
                setConnectionStatus(ConnectionStatus.OPEN);
              }
            }, 10);
          }
          const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(
            navigator.userAgent
          );

          if (!socket || socket.readyState !== WebSocket.OPEN) {
            // Reset cooldown when user explicitly brings app to foreground
            reconnectionState.inReconnectionCooldown = false;

            logger.debug(
              "Connection not open, connecting after visibility change"
            );
            connect();

            // Restore join state after reconnection
            const activeChatId = localStorage.getItem(ACTIVE_CHAT_KEY);
            if (activeChatId) {
              logger.debug(
                `Will restore active chat: ${activeChatId} after reconnection`
              );

              // After connecting, explicitly join the chat
              setTimeout(() => {
                if (connectionStatus === ConnectionStatus.OPEN) {
                  logger.debug(
                    `Rejoining chat ${activeChatId} after visibility change`
                  );
                  sendJoin(activeChatId);
                }
              }, 1000);
            }
          } else if (isMobileDevice) {
            // For mobile devices, check connection health with a ping
            try {
              sendMessage({
                type: "ping",
                version: 1,
                source: "web",
                data: { timestamp: Date.now() },
              });
              logger.debug("Sent ping to verify connection health");
            } catch (e) {
              logger.debug("Ping failed, reconnecting", e);
              connect();
            }
          }
        }, 500);
      } else {
        lastActiveTimeRef.current = Date.now();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (focusTimeout) clearTimeout(focusTimeout);
    };
  }, [socket, connect, sendJoin, sendMessage, connectionStatus]);

  const disconnect = useCallback(
    (preserveState = false) => {
      if (socket) {
        // Don't manage active chat here anymore
        try {
          socket.close(1000, "Closing normally");
        } catch (e) {
          console.error("Error closing socket:", e);
        }
        setSocket(null);
      }
    },
    [socket]
  );

  const sendTyping = useCallback(
    (chatId: string, typing: boolean) => {
      sendMessage({
        type: kMessageTypeTyping,
        version: 1,
        source: "web",
        data: { chatId, typing },
      });
    },
    [sendMessage]
  );

  const sendLeave = useCallback(
    (chatId: string) => {
      logger.debug(`Explicitly leaving chat: ${chatId}`);

      sendMessage({
        type: kMessageTypeChatLeave,
        version: 1,
        source: "web",
        data: { chatId },
      });
    },
    [sendMessage]
  );

  const sendTextMessage = useCallback(
    (
      chatId: string,
      message: string,
      existingTempId: string,
      replyToId?: string
    ) => {
      // Temporary id replaced with actual id server side
      const tempId = existingTempId;
      sendMessage({
        type: kMessageTypeMessage,
        version: 1,
        source: "web",
        data: {
          chatId,
          senderId: user?.userId,
          content: {
            type: kContentMessageTypeText,
            text: message,
          },
          replyTo: replyToId || null,
          metadata: {
            tempId: tempId,
          },
        },
      });
      return tempId;
    },
    [sendMessage, user?.userId]
  );

  const sendImageMessage = useCallback(
    (
      chatId: string,
      imageUrl: string,
      message: string | null,
      existingTempId: string,
      replyToId?: string
    ) => {
      const tempId = existingTempId;

      sendMessage({
        type: kMessageTypeMessage,
        version: 1,
        source: "web",
        data: {
          chatId,
          senderId: user?.userId,
          content: {
            type: kContentMessageTypeImage,
            mediaUrl: imageUrl,
            text: message,
          },
          replyTo: replyToId || null,
          metadata: {
            tempId: tempId,
          },
        },
      });

      return tempId;
    },
    [sendMessage, user?.userId]
  );

  const sendReadReceipt = useCallback(
    (chatId: string, messageIds: string[]) => {
      if (!messageIds.length) return;

      logger.debug(
        `Sending read receipt for ${messageIds.length} messages in chat ${chatId}`
      );
      sendMessage({
        type: kMessageTypeMessageRead,
        version: 1,
        source: "web",
        data: {
          chatId,
          messageIds,
          readAt: new Date().toISOString(),
        },
      });
    },
    [sendMessage]
  );

  const onMessage = useCallback(
    (handler: (message: WebSocketMessage) => void) => {
      messageHandlers.current.add(handler);
    },
    []
  );

  const removeMessageHandler = useCallback(
    (handler: (message: WebSocketMessage) => void) => {
      messageHandlers.current.delete(handler);
    },
    []
  );

  useEffect(() => {
    if (isAuthenticated && user && accessToken) {
      connect();
    } else {
      disconnect(false);
    }

    // Don't disconnect on every unmount, only when auth changes
    return () => {
      // Only disconnect if we're actually logging out or the app is completely closing
      if (!document.hidden && (!isAuthenticated || !user || !accessToken)) {
        logger.debug("WebSocket cleanup on auth change");
        disconnect(false);
      } else {
        logger.debug("Component unmounting, but keeping WebSocket alive");
      }
    };
  }, [isAuthenticated, user, accessToken, connect, disconnect]);

  const contextValue = useMemo(
    () => ({
      sendTyping,
      sendTextMessage,
      sendImageMessage,
      sendJoin,
      sendLeave,
      sendReadReceipt,
      onMessage,
      removeMessageHandler,
      connectionStatus,
      connect,
      disconnect,
    }),
    [
      sendTyping,
      sendTextMessage,
      sendImageMessage,
      sendJoin,
      sendLeave,
      sendReadReceipt,
      onMessage,
      removeMessageHandler,
      connectionStatus,
      connect,
      disconnect,
    ]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

import React, { createContext, useState, useContext, ReactNode } from "react";
import { Message } from "../models/message";

interface ChatContextType {
  replyingTo: Message | null;
  setReplyingTo: (message: Message | null) => void;
}

const ChatContext = createContext<ChatContextType>({
  replyingTo: null,
  setReplyingTo: () => {},
});

export const useChatContext = () => useContext(ChatContext);

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [replyingTo, setReplyingTo] = useState<Message | null>(null);

  return (
    <ChatContext.Provider value={{ replyingTo, setReplyingTo }}>
      {children}
    </ChatContext.Provider>
  );
};

/**
 * IMPORTANT: This file is duplicated in:
 * - /public/cache-utils.js (Service Worker version)
 * - webapp/src/services/sharedStore.ts (React app version)
 *
 * If you make changes to one file, update the other!
 */

const CACHE_VERSION = "1.1"; // Increment when cache format changes
const CACHE_NAME = `giojoy-shared-storage-${CACHE_VERSION}`;

/**
 * Gets a value from the shared cache, remove the key from storage in case of error
 * @param key The key to retrieve
 * @returns The stored value or null if not found
 */
export async function getSharedValue(key: string) {
  try {
    return await internalGetSharedValue(key);
  } catch (e) {
    console.error("Cache error, resetting:", e);
    try {
      await caches.delete(CACHE_NAME); // Reset the broken cache
    } catch {}
    return null;
  }
}

/**
 * Gets a value from the shared cache
 * @param key The key to retrieve
 * @returns The stored value or null if not found
 */
async function internalGetSharedValue(key: string): Promise<string | null> {
  try {
    if (!("caches" in window)) {
      throw new Error("Cache API not supported");
    }

    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(`/__storage/${key}`);
    if (!response) return null;

    return response.text();
  } catch (error) {
    console.error("Read error:", error);
    return null;
  }
}

/**
 * Sets a value in the shared cache
 * @param key The key to store
 * @param value The value to store
 * @returns True if successful
 */
export async function setSharedValue(
  key: string,
  value: string | boolean
): Promise<boolean> {
  try {
    if (!("caches" in window)) {
      throw new Error("Cache API not supported");
    }

    const cache = await caches.open(CACHE_NAME);
    const response = new Response(String(value));
    await cache.put(`/__storage/${key}`, response);
    return true;
  } catch (error) {
    console.error("Write error:", error);
    return false;
  }
}

/**
 * Deletes a value from the shared cache
 * @param key The key to delete
 * @returns True if the key existed and was removed
 */
export async function deleteSharedValue(key: string): Promise<boolean> {
  try {
    if (!("caches" in window)) {
      throw new Error("Cache API not supported");
    }

    const cache = await caches.open(CACHE_NAME);
    return await cache.delete(`/__storage/${key}`);
  } catch (error) {
    console.error("Delete error:", error);
    return false;
  }
}

export async function clearSharedCache(pattern: RegExp): Promise<boolean> {
  try {
    if (!("caches" in window)) {
      throw new Error("Cache API not supported");
    }

    const cache = await caches.open(CACHE_NAME);
    const keys = await cache.keys();

    // Delete all values matching pattern
    for (const request of keys) {
      if (pattern.test(request.url)) {
        await cache.delete(request);
      }
    }
  } catch (error) {
    console.error("Delete error:", error);
    return false;
  }
  return true;
}

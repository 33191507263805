import React, { forwardRef } from "react";

import { MessageBubbleWrapper } from "./MessageBubbleWrapper";
import { Message } from "../../models/message";
import { ChatParticipant } from "../../models/chatParticipant";
import { ReplyContent } from "./ReplyContent";
import { useChatContext } from "../../context/ChatContext";
import { useReplyMessage } from "../../hooks/useReplyMessage";

interface MessageBubbleProps {
  message: Message;
  currentUserId: string;
  participants: ChatParticipant[];
}

export const MessageBubble = forwardRef<HTMLDivElement, MessageBubbleProps>(
  ({ message, currentUserId, participants }, ref) => {
    const { setReplyingTo } = useChatContext();

    const replyToMessage = useReplyMessage(message.replyTo, message.chatId);

    const handleReply = () => {
      setReplyingTo(message);
    };

    return (
      <MessageBubbleWrapper
        ref={ref}
        message={message}
        currentUserId={currentUserId}
        participants={participants}
        onReplyClick={handleReply}
        replyContent={
          replyToMessage && (
            <ReplyContent
              replyMessage={replyToMessage}
              currentUserId={currentUserId}
              participants={participants}
            />
          )
        }
        className="px-4 py-2"
      >
        <p className="text-sm">
          {message.content.text || "[messaggio mancante]"}
        </p>
      </MessageBubbleWrapper>
    );
  }
);

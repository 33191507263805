import React from "react";
import { useIOSNotification } from "../hooks/useIOSNotification";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline"; // Safari-like share icon
import { useAuth } from "../features/auth/hooks/useAuth";
import { userRoleAdmin } from "../consts";

export const IOSInstallationBanner: React.FC = () => {
  const { isAuthenticated, hasRole } = useAuth();
  const { needsInstallation } = useIOSNotification();

  if (!isAuthenticated || !hasRole(userRoleAdmin) || !needsInstallation)
    return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-blue-500 text-white p-4 text-center z-50 pb-safe">
      <p className="font-bold">Attiva le notifiche</p>
      <p className="text-sm pb-[env(safe-area-inset-bottom)] flex items-start justify-center">
        Premi <ArrowUpOnSquareIcon className="h-8 w-8" /> dalla barra inferiore e seleziona "Aggiungi a Home" per attivcarle
      </p>
    </div>
  );
};

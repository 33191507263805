export const kMessageTypeTyping = "chat.typing" as const;
export const kMessageTypeUserPresence = "user.presence" as const;
export const kMessageTypeAuthSuccess = "auth.success" as const;
export const kMessageTypeAuthError = "auth.error" as const;
export const kMessageTypeMessage = "chat.message" as const;
export const kMessageTypeChatJoin = "chat.join" as const;
export const kMessageTypeChatLeave = "chat.leave" as const;
export const kMessageTypeMessageRead = "chat.message.read" as const;

export const kContentMessageTypeText = "TEXT" as const;
export const kContentMessageTypeImage = "IMAGE" as const;
export const kContentMessageTypeFile = "FILE" as const;
export const kContentMessageTypePoll = "POLL" as const;
export const kContentMessageTypeLink = "LINK" as const;

type MessageType =
  | typeof kMessageTypeTyping
  | typeof kMessageTypeAuthSuccess
  | typeof kMessageTypeAuthError
  | typeof kMessageTypeMessage
  | typeof kMessageTypeChatJoin
  | typeof kMessageTypeChatLeave
  | typeof kMessageTypeUserPresence
  | typeof kMessageTypeMessageRead;

export interface WebSocketMessage {
  type: MessageType;
  version: 1;
  source: string;
  data: any;
}

export type WsMessageJoinChat = {
  type: typeof kMessageTypeChatJoin;
  version: 1;
  source: "web";
  data: {
    chatId: string;
  };
};

export type WsMessageLeaveChat = {
  type: typeof kMessageTypeChatLeave;
  version: 1;
  source: "web";
  data: {
    chatId: string;
  };
};

export type WsMessageTyping = {
  type: typeof kMessageTypeTyping;
  version: 1;
  source: "web";
  data: {
    chatId: string;
    typing: boolean;
  };
};

export type WsMessageMessage = {
  type: typeof kMessageTypeMessage;
  version: 1;
  source: "web";
  data: {
    id?: string;
    chatId: string;
    senderId: string;
    content: {
      type: string;
      text?: string;
      mediaUrl?: string;
      pollData?: any;
      linkPreview?: any;
    };
    reactions?: any[];
    replyTo?: string;
    status?: string;
    readBy?: any[];
    metadata?: {
      tempId?: string;
      [key: string]: any;
    };
  };
};

export type WsMessagePresence = {
  type: typeof kMessageTypeUserPresence;
  version: 1;
  source: "web";
  data: {
    chatId: string;
    typing: boolean;
  };
};

export type WsMessageRead = {
  type: typeof kMessageTypeMessageRead;
  version: number;
  source: string;
  data: {
    chatId: string;
    messageIds: string[];
    readAt: string;
  };
};

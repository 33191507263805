import React, { forwardRef } from "react";
import { X } from "lucide-react";
import { useChatImageUpload } from "../../hooks/useChatImageUpload";
import { MessageBubbleWrapper } from "./MessageBubbleWrapper";
import { ChatParticipant } from "../../models/chatParticipant";
import { Message } from "../../models/message";

interface UploadingImageBubbleProps {
  messageId: string;
  chatId: string;
  currentUserId?: string;
  isMine: boolean;
  participants: ChatParticipant[]; // Add this
  message: Message; // Add this
  progress?: number;
  preview?: string | null;
}

const UploadingImageBubble = forwardRef<
  HTMLDivElement,
  UploadingImageBubbleProps
>(
  (
    {
      messageId,
      chatId,
      currentUserId,
      isMine,
      message, // New field
      participants, // New field 
      progress: externalProgress,
      preview: externalPreview,
    },
    ref
  ) => {
    const { 
      cancelUpload: cancelImageUpload,
      progress: hookProgress,
      previewUrl: hookPreview,
    } = useChatImageUpload(chatId, currentUserId);

    const progress = externalProgress !== undefined ? externalProgress : hookProgress;
    const preview = externalPreview !== undefined ? externalPreview : hookPreview;

    const handleCancel = () => {
      cancelImageUpload(messageId);
    };

    return (
      <MessageBubbleWrapper
        ref={ref}
        message={message} // Use message
        currentUserId={currentUserId || ""}
        participants={participants}
        className="p-0 overflow-hidden"
      >
        <div className="relative">
          {preview ? (
            <img
              src={preview}
              alt="Uploading"
              className="w-48 object-cover opacity-60"
            />
          ) : (
            <div className="w-48 h-48 bg-gray-100 flex items-center justify-center">
              <svg
                className="w-12 h-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
          )}

          {/* Cancel button */}
          <button
            onClick={handleCancel}
            className="absolute top-2 right-2 bg-black/50 rounded-full p-1 text-white hover:bg-black/70"
          >
            <X size={16} />
          </button>
        </div>

        {/* Progress bar container */}
        <div className="bg-black/20 p-2">
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-700">
              Caricamento... {progress}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-1.5 mt-1">
            <div
              className="bg-blue-600 h-1.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </MessageBubbleWrapper>
    );
  }
);

export default UploadingImageBubble;
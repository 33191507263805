import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useContext } from "react";
import { WebSocketContext } from "../context/WebSocketContext";
import { kMessageTypeMessage } from "../context/WebSocketMessage";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";

/**
 * Hook that handles replacing temporary messages with permanent ones
 * when server responses arrive via WebSocket.
 */
export const useTempMessageHandler = () => {
  const client = useApolloClient();
  const ws = useContext(WebSocketContext);

  useEffect(() => {
    // Handler for message replacement
    const handleMessageReplacement = (message: any) => {
      if (message.type !== kMessageTypeMessage || !message.data) {
        return;
      }

      const serverMessage = message.data;

      // Check if there's a tempId in the metadata
      const tempId = serverMessage.metadata?.tempId;
      if (!tempId) return;

      console.log(
        `Replacing temporary message ${tempId} with server message ${serverMessage.id}`
      );

      // Update Apollo cache to remove the temporary message
      client.cache.modify({
        fields: {
          messages(existingMessages = {}, { readField }) {
            if (!existingMessages.edges || !existingMessages.edges.length) {
              return existingMessages;
            }

            // Find the temporary message
            const tempMessageIndex = existingMessages.edges.findIndex(
              (edge: any) => readField("id", edge.node) === tempId
            );

            if (tempMessageIndex >= 0) {
              // Create new server message edge
              const newServerEdge = {
                __typename: "MessageEdge",
                cursor: serverMessage.id,
                node: client.cache.writeFragment({
                  data: {
                    ...serverMessage,
                    __typename: "Message",
                    // Make sure all fields needed by your components are present
                    createdAt:
                      serverMessage.createdAt || new Date().toISOString(),
                    updatedAt:
                      serverMessage.updatedAt || new Date().toISOString(),
                  },
                  fragment: MESSAGE_FIELDS_FRAGMENT,
                }),
              };

              // Remove temp message and add server message
              const newEdges = [...existingMessages.edges];
              newEdges.splice(tempMessageIndex, 1, newServerEdge); // Replace instead of remove

              return {
                ...existingMessages,
                edges: newEdges,
              };
            }

            return existingMessages;
          },
        },
      });
    };

    // Subscribe to WebSocket messages
    ws?.onMessage(handleMessageReplacement);

    // Clean up subscription when component unmounts
    return () => {
      ws?.removeMessageHandler(handleMessageReplacement);
    };
  }, [client, ws]);
};

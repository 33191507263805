import React, { forwardRef, ReactNode } from "react";
import { Message } from "../../models/message";
import { ChatParticipant } from "../../models/chatParticipant";
import { ReadStatus } from "./ReadStatus";

interface MessageBubbleWrapperProps {
  message: Message;
  currentUserId: string;
  participants: ChatParticipant[];
  children: ReactNode;
  replyContent?: ReactNode; // Will hold the reply preview component
  onReplyClick?: () => void; // Handler for reply action
  className?: string; // Additional styling for the content
}

export const MessageBubbleWrapper = forwardRef<HTMLDivElement, MessageBubbleWrapperProps>(
  ({ message, currentUserId, participants, children, replyContent, onReplyClick, className = "" }, ref) => {
    const isSentByMe = message.senderId === currentUserId;
    const sender = participants.find(p => p.userId === message.senderId);

    return (
      <div
        ref={ref}
        className={`flex ${
          isSentByMe ? "justify-end" : "justify-start"
        }`}
      >
        <div
          className={`flex items-end space-x-2 max-w-[70%] ${
            isSentByMe ? "flex-row-reverse space-x-reverse" : ""
          }`}
        >
          {/* Avatar */}
          {!isSentByMe && (
            <img
              src={sender?.avatarUrl}
              alt="avatar"
              className="w-8 h-8 rounded-full object-cover"
            />
          )}
          
          <div className="relative group">
            {/* Reply content if exists */}
            {replyContent}
            
            {/* Main message content */}
            <div
              className={`rounded-lg relative ${className} ${
                isSentByMe
                  ? "bg-blue-500 text-white"
                  : "bg-white border border-gray-200"
              }`}
              onClick={onReplyClick ? undefined : undefined} // Can handle click events if needed
            >
              {children}
              
              {/* Reply button (optional) */}
              {onReplyClick && (
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    onReplyClick();
                  }}
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 p-1 rounded-full bg-gray-200/50 hover:bg-gray-200 transition-opacity"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="9 17 4 12 9 7"></polyline>
                    <path d="M20 18v-2a4 4 0 0 0-4-4H4"></path>
                  </svg>
                </button>
              )}
            </div>
            
            {/* Timestamp and read status */}
            <span className="text-xs text-gray-500 mt-1 block">
              {new Date(message.createdAt).toLocaleTimeString()}
            </span>
            <ReadStatus
              status={message.status}
              readBy={message.readBy}
              isOutgoing={isSentByMe}
            />
          </div>
        </div>
      </div>
    );
  }
);
import React, { useContext, useEffect, useState } from "react";
import ChatsList from "../components/ChatsList";
import { Chat } from "../models/chat";

import { useQuery } from "@apollo/client";
import { WebSocketContext } from "../context/WebSocketContext";
import useCachedProfile from "../../../hooks/useCachedProfile";
import { CHAT_QUERY } from "../api/queries";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ACTIVE_CHAT_KEY } from "../consts";
import { useTempMessageHandler } from "../hooks/useTempMessageHandler";
import { ChatProvider } from "../context/ChatContext";
import { useActiveChat } from "../../../context/ActiveChatContext";
import ChatWindow2 from "../components/ChatWindow2";

const ChatLayout = React.memo(() => {
  const [searchParams] = useSearchParams();
  const { activeChat, setActiveChat } = useActiveChat();

  const [isMobileListVisible, setIsMobileListVisible] = useState(true);
  const { user } = useCachedProfile();

  const navigate = useNavigate();
  const location = useLocation();

  useTempMessageHandler();

  const ws = useContext(WebSocketContext);

  const chatIdFromUrl = searchParams.get("chat_id");
  const effectiveChatId =
    chatIdFromUrl || localStorage.getItem(ACTIVE_CHAT_KEY);

  const { data: chatData, loading: chatLoading } = useQuery(CHAT_QUERY, {
    variables: { chatId: effectiveChatId },
    skip: !effectiveChatId,
  });

  const handleChatSelect = (chat: Chat) => {
    setActiveChat(chat); // This now handles joining and leaving chats
    if (window.innerWidth < 768) {
      setIsMobileListVisible(false);
    }
  };

  useEffect(() => {
    if (!chatLoading && chatData?.chat && !activeChat) {
      console.log(
        "Restoring chat from",
        chatIdFromUrl ? "URL" : "localStorage"
      );
      // Use ActiveChatContext to set the chat
      setActiveChat(chatData.chat);
      setIsMobileListVisible(false);
    }
  }, [chatData, chatLoading, activeChat, chatIdFromUrl, setActiveChat]);

  const handleBackToList = () => {
    if (window.innerWidth < 768) {
      if (location.state?.from) {
        setActiveChat(null);
        navigate(location.state.from);
        return;
      }

      if (!isMobileListVisible) {
        setIsMobileListVisible(true);
        setActiveChat(null);
        return;
      }

      // Fallback for any other case - just go back in history
      navigate(-1);
    } else {
      // On desktop, just show the list without clearing active chat
      setIsMobileListVisible(true);
    }
  };

  return (
    <div className="flex w-full h-[80vh] overflow-hidden mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {/* Left Column: Conversations List */}
      <div
        className={`
          w-full md:w-80 h-full bg-white flex-shrink-0
          ${isMobileListVisible ? "block" : "hidden"} md:!block
        `}
      >
        <ChatsList
          onSelectChat={handleChatSelect}
          ws={ws}
          currentUserId={user?.userId}
        />
      </div>

      {/* Right Column: Chat Window */}
      <div
        className={`
          flex-1 h-full min-w-0
          ${!isMobileListVisible ? "block" : "hidden"} md:!block
        `}
      >
        {activeChat ? (
          <div className="flex flex-col h-full relative">
            <div className="flex-1 md:pt-0 pt-2 h-full overflow-hidden">
              <ChatProvider>
                <ChatWindow2
                  chat={activeChat}
                  currentUserId={user?.userId}
                  onSelectChat={handleChatSelect}
                  onBack={handleBackToList} // Pass the back handler
                  isMobile={!isMobileListVisible} // Pass mobile state
                />
              </ChatProvider>
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            <p>Seleziona una chat per iniziare a chattare</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default ChatLayout;

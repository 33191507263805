import { useState, useEffect, useCallback, useRef } from "react";

export const useChatDraft = (chatId: string) => {
  const [hasDraft, setHasDraft] = useState(false);
  const userEditedRef = useRef(false);

  // Create draft key for this chat
  const getDraftKey = useCallback(() => `chat_draft_${chatId}`, [chatId]);

  // Load draft from localStorage
  const loadDraft = useCallback(() => {
    // Skip if user has already edited text in this session
    if (userEditedRef.current) return null;

    try {
      const draftKey = getDraftKey();
      const savedDraft = localStorage.getItem(draftKey);

      if (savedDraft) {
        setHasDraft(true);
        return savedDraft;
      }
    } catch (e) {
      console.error("Error loading draft:", e);
    }

    setHasDraft(false);
    return null;
  }, [getDraftKey]);

  // Load draft on init
  useEffect(() => {
    userEditedRef.current = false;
    loadDraft();
  }, [chatId, loadDraft]);

  // Save draft to localStorage
  const saveDraft = useCallback(
    (text: string) => {
      userEditedRef.current = true;

      try {
        const draftKey = getDraftKey();
        if (text.trim()) {
          localStorage.setItem(draftKey, text);
          setHasDraft(true);
        } else {
          localStorage.removeItem(draftKey);
          setHasDraft(false);
        }
      } catch (e) {
        console.error("Error saving draft:", e);
      }
    },
    [getDraftKey]
  );

  // Clear draft from localStorage
  const clearDraft = useCallback(() => {
    try {
      localStorage.removeItem(getDraftKey());
      setHasDraft(false);
    } catch (e) {
      console.error("Error clearing draft:", e);
    }
  }, [getDraftKey]);

  return {
    hasDraft,
    saveDraft,
    clearDraft,
    loadDraft,
  };
};

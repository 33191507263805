import { useState } from "react";
import ModalAlert from "../../../components/ModalAlert";
import { useMutation } from "@apollo/client";
import { REQUEST_DELETE_ACCOUNT } from "../api/userService";
import { useAuth } from "../../auth/hooks/useAuth";
import { CHANGE_PASSWORD_MUTATION } from "../api/mutations";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function Account() {
  const { logout } = useAuth();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [requestDeleteAccount] = useMutation(REQUEST_DELETE_ACCOUNT);
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);
  const [showPasswordUpdatedModal, setShowPasswordUpdatedModal] =
    useState(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError("");
    setApiError("");

    if (newPassword !== confirmPassword) {
      setPasswordError("Le password non corrispondono");
      return;
    }
    try {
      const pwdChangeRes = await changePassword({
        variables: {
          currentPassword: oldPassword,
          newPassword: newPassword,
        },
      });

      if (pwdChangeRes.data.changePassword) {
        setShowPasswordUpdatedModal(true);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error: any) {
      console.log(error);
      if (error.graphQLErrors.length > 0) {
        setApiError(error.graphQLErrors[0].extensions.message);
      } else {
        setApiError("Errore durante l'aggiornamento della password");
      }
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 space-y-8">
      <h1 className="text-2xl font-bold mb-6">Impostazioni account</h1>
      {/* Password Change Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Cambia Password</h2>
        <form onSubmit={handlePasswordChange} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password attuale
            </label>
            <input
              type="password"
              placeholder="Inserisci qui la tua password attuale"
              value={oldPassword}
              required
              onChange={(e) => setOldPassword(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nuova Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Inserisci qui la tua nuova password"
                value={newPassword}
                required
                onChange={(e) => setNewPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
                ) : (
                  <EyeIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
                )}
              </button>
            </div>{" "}
            <p className="mt-2 text-sm text-gray-500">
              La password deve avere almeno 8 caratteri, includendo una
              maiuscola, una minuscola, un numero e un simbolo
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Conferma Nuova Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Conferma la tua nuova password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {/* Error Messages */}
          {passwordError && (
            <div className="text-red-600 text-sm">{passwordError}</div>
          )}
          {apiError && <div className="text-red-600 text-sm">{apiError}</div>}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Aggiorna Password
          </button>
        </form>
      </div>
      {/* Delete Account Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4"> Cancellazione account</h2>
        <p className="text-gray-600 mb-4">
          Attenzione! La cancellazione dell'account è irreversibile, tutti i
          tuoi dati verranno rimossi.
        </p>
        <button
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Cancellazione account
        </button>
      </div>
      <ModalAlert
        title="Sei sicuro di voler cancellare il tuo account?"
        isOpen={showDeleteModal}
        actions={[
          {
            label: "Conferma",
            onClick: async () => {
              await requestDeleteAccount();
              logout();
              setShowDeleteModal(false);
            },
            color: "bg-red-600 hover:bg-red-700",
          },
        ]}
        onClose={function (): void {
          setShowDeleteModal(false);
        }}
        message="Se confermi la richiesta il tuo account verrà definitivamente eliminato entro 24 ore dalla richiesta stessa."
      />
      {/* Password Updated Modal */}
      <ModalAlert
        title="Password aggiornata"
        isOpen={showPasswordUpdatedModal}
        actions={[ ]}
        onClose={function (): void {
            setShowPasswordUpdatedModal(false);
        }}
        cancelLabel="Chiudi"
        message="La tua password è stata aggiornata con successo."
      />
      
    </div>
  );
}

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import graphQLClient from "../../../services/gqlClients";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";
import {
  ConnectionStatus,
  WebSocketContext,
} from "../context/WebSocketContext";
import { Message } from "../models/message";
import { useChatImageUpload } from "./useChatImageUpload";
import { useChatDraft } from "./useChatDraft";
import { useTypingIndicator } from "./useTypingIndicator";

const generateTempId = () =>
  `temp-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

interface UseChatInputProps {
  chatId: string;
  currentUserId?: string;
  replyingTo: Message | null;
  onReplyComplete?: () => void;
  onMessageSent?: () => void;
}

export const useChatInput = ({
  chatId,
  currentUserId,
  replyingTo,
  onReplyComplete,
  onMessageSent,
}: UseChatInputProps) => {
  // WebSocket connection
  const ws = useContext(WebSocketContext);
  const isConnected = ws?.connectionStatus === ConnectionStatus.OPEN;
  const prevConnectedRef = useRef(isConnected);

  // Core message state with a single source of truth
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  // Extract draft functionality to a dedicated hook
  const { hasDraft, saveDraft, clearDraft, loadDraft } = useChatDraft(chatId);

  // Extract typing indicator logic to a dedicated hook
  const { sendTypingIndicator, clearTypingIndicators } = useTypingIndicator({
    chatId,
    isConnected,
    websocket: ws,
  });

  // Image upload functionality (already nicely abstracted)
  const { uploadImage, isUploading } = useChatImageUpload(
    chatId,
    currentUserId
  );

  // Reset when chat changes
  useEffect(() => {
    setMessage("");
    // Only load drafts when disconnected
    if (!isConnected) {
      loadDraft();
    }
    clearTypingIndicators();
  }, [chatId, isConnected, loadDraft, clearTypingIndicators]);

  useEffect(() => {
    // If connection restored, check for pending drafts
    if (isConnected && !prevConnectedRef.current) {
      const draft = loadDraft();
      if (draft && draft.trim()) {
        // Set the draft message to be ready for sending
        setMessage(draft);
        // Optionally auto-send the draft
        // setTimeout(() => handleSendTextMessage(), 500);
      }
    }

    prevConnectedRef.current = isConnected;
  }, [isConnected, loadDraft]);

  // Handle input changes
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setMessage(newValue);

      // Only save drafts when disconnected
      if (!isConnected) {
        saveDraft(newValue);
      }

      // Handle typing indicators when connected
      if (isConnected) {
        if (newValue.trim()) {
          sendTypingIndicator(true);
        } else {
          sendTypingIndicator(false);
        }
      }
    },
    [isConnected, saveDraft, sendTypingIndicator]
  );

  // Send text message
  const handleSendTextMessage = useCallback(async () => {
    if (!message.trim() || !isConnected) return;

    if (!isConnected) {
      // If disconnected, save as draft
      saveDraft(message);
      return;
    }

    try {
      const currentMessage = message;
      const replyToId = replyingTo?.id;
      const tempId = generateTempId();
      const now = new Date().toISOString();

      // Stop typing and clear input
      sendTypingIndicator(false);
      setSending(true);
      setMessage("");
      clearDraft();

      // Create optimistic message
      const optimisticMessage = {
        __typename: "Message",
        id: tempId,
        createdAt: now,
        updatedAt: now,
        chatId,
        senderId: currentUserId,
        content: {
          __typename: "MessageContent",
          type: "TEXT",
          text: currentMessage,
          mediaUrl: "",
          pollData: null,
          linkPreview: null,
        },
        replyTo: replyToId || null,
        status: "sent",
        readBy: [],
        reactions: [],
      };

      // Update Apollo cache
      graphQLClient.cache.modify({
        fields: {
          messages(existingMessages = {}, { storeFieldName }) {
            // Only update the right chat's messages
            const storeFieldNameMatch =
              storeFieldName.match(/"chatId":"([^"]+)"/);
            const storeFieldChatId = storeFieldNameMatch
              ? storeFieldNameMatch[1]
              : null;

            if (storeFieldChatId !== chatId) return existingMessages;

            const newEdge = {
              __typename: "MessageEdge",
              cursor: tempId,
              node: graphQLClient.cache.writeFragment({
                data: optimisticMessage,
                fragment: MESSAGE_FIELDS_FRAGMENT,
              }),
            };

            return {
              ...existingMessages,
              totalCount: existingMessages.totalCount + 1,
              edges: [newEdge, ...existingMessages.edges],
            };
          },
        },
      });

      if (onReplyComplete) onReplyComplete();
      ws?.sendTextMessage(chatId, currentMessage, tempId, replyToId);
      if (onMessageSent) onMessageSent();
    } catch (error) {
      console.error("Failed to send message:", error);
      // Restore message on error
      setMessage(message);
    } finally {
      setSending(false);
    }
  }, [
    message,
    isConnected,
    saveDraft,
    replyingTo?.id,
    sendTypingIndicator,
    clearDraft,
    chatId,
    currentUserId,
    onReplyComplete,
    ws,
    onMessageSent,
  ]);

  // Handle image upload
  const handleSendImageMessage = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      try {
        // Stop typing indicator when sending an image
        sendTypingIndicator(false);

        await uploadImage(file);
        if (onMessageSent) onMessageSent();
      } catch (error) {
        console.error("Failed to upload image:", error);
      } finally {
        // Clear input
        e.target.value = "";
      }
    },
    [uploadImage, onMessageSent, sendTypingIndicator]
  );

  // Clean up on unmount
  useEffect(() => {
    return () => {
      sendTypingIndicator(false);
    };
  }, [sendTypingIndicator]);

  return {
    message,
    setMessage,
    sending,
    isConnected,
    hasDraft: hasDraft && !isConnected,
    isUploading,
    handleInputChange,
    handleSendTextMessage,
    handleSendImageMessage,
    clearDraft,
  };
};

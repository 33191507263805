//import { MessageSquareText } from "lucide-react";
import { useApolloClient, useMutation } from "@apollo/client";
import { ChatParticipant } from "../models/chatParticipant";
import { CREATE_DIRECT_CHAT_MUTATION } from "../api/mutations";
import { Chat } from "../models/chat";
import { CHATS_QUERY } from "../api/queries";
import useGetProfile from "../../user/hooks/useGetProfile";
import React from "react";

const ChatParticipants: React.FC<{
  participants: ChatParticipant[];
  onSelectChat?: (chat: Chat) => void;
}> = ({ participants, onSelectChat }) => {
  const client = useApolloClient();
  const { user } = useGetProfile(); // Get the current user

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createDirectChat, { loading }] = useMutation(
    CREATE_DIRECT_CHAT_MUTATION,
    {
      onCompleted: (data) => {
        if (data.createDirectChat && onSelectChat) {
          // First select the chat
          onSelectChat(data.createDirectChat);

          // Then force a refetch of the chats list to ensure it's updated
          client.refetchQueries({
            include: [CHATS_QUERY],
          });
        }
      },
      onError: (error) => {
        console.error("Error creating direct chat:", error);
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCreateDirectChat = (participantId: string) => {
    createDirectChat({
      variables: {
        recipientId: participantId,
      },
    });
  };

  const mapRoleToHumanReadable = (role: string) => {
    switch (role) {
      case "ADMIN":
        return "Amministratore";

      default:
        return "";
    }
  };

  return (
    <div className="w-full">
      <div className="flow-root">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200 bg-white">
              {participants.map((participant) => {
                const isCurrentUser = participant.userId === user?.userId;

                return (
                  <tr key={participant.userId}>
                    {/* Left cell - avatar and name */}
                    <td className="whitespace-nowrap py-4 pl-3 pr-2 text-sm">
                      <div className="flex items-center">
                        <div className="size-10 shrink-0">
                          <img
                            alt=""
                            src={participant.avatarUrl}
                            className="size-10 rounded-full"
                          />
                        </div>
                        <div className="ml-3 max-w-[120px] sm:max-w-none overflow-hidden">
                          <div className="font-medium text-gray-900 truncate">
                            {participant.displayName}
                            {isCurrentUser && (
                              <span className="ml-2 text-xs text-gray-500">
                                (tu)
                              </span>
                            )}
                          </div>
                          <div className="mt-1 text-gray-500 truncate text-xs">
                            {mapRoleToHumanReadable(participant.role)}
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* Right cell - message button (hidden for current user) */}
                    <td className="relative py-4 pl-2 pr-3 text-right text-sm font-medium">
                      {/* {!isCurrentUser && (
                        <button
                          onClick={() =>
                            handleCreateDirectChat(participant.userId)
                          }
                          disabled={loading}
                          aria-label="Create direct chat"
                          className="inline-flex items-center rounded-full px-2 sm:px-3 py-1.5 bg-white text-gray-900 border border-gray-900 hover:bg-gray-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <MessageSquareText
                            size={16}
                            className="mr-0.5 sm:mr-1"
                          />
                          <span className="hidden xs:inline">
                            {loading ? "Connetto..." : "Messaggio"}
                          </span>
                        </button>
                      )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChatParticipants);

import React, { forwardRef } from "react";
import { Message } from "../../models/message";
import { ChatParticipant } from "../../models/chatParticipant";
import { ImageBubble } from "./ImageBubble";
import UploadingImageBubble from "./UploadingImageBubble";
import FailedImageBubble from "./FailedImageBubble";
import { useFileUploadContext } from "../../../../hooks/FileUploadContext";

interface MessageImageHandlerProps {
  message: Message;
  currentUserId: string;
  chatId: string;
  participants: ChatParticipant[];
}

export const MessageImageHandler = forwardRef<
  HTMLDivElement,
  MessageImageHandlerProps
>(({ message, currentUserId, chatId, participants }, ref) => {
  const { uploads } = useFileUploadContext();
  const isMine = message.senderId === currentUserId;

  if (message.content.type === "UPLOADING_IMAGE") {
    const upload = uploads[message.id];

    return (
      <UploadingImageBubble
        ref={ref}
        messageId={message.id}
        chatId={chatId}
        currentUserId={currentUserId}
        isMine={isMine}
        message={message}
        participants={participants}
        progress={upload?.progress}
        preview={upload?.previewUrl}
      />
    );
  }

  if (message.content.type === "FAILED_IMAGE") {
    return (
      <FailedImageBubble
        ref={ref}
        message={message}
        chatId={chatId}
        currentUserId={currentUserId}
        isMine={isMine}
        participants={participants}
      />
    );
  }

  return (
    <ImageBubble
      ref={ref}
      message={message}
      currentUserId={currentUserId}
      participants={participants}
    />
  );
});

MessageImageHandler.displayName = "MessageImageHandler";

export const createLogger = (component: string) => {
  return {
    debug: (...args: any[]) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`[${component} ${new Date().toISOString().slice(11, 19)}]`, ...args);
      }
    },
    warn: (...args: any[]) => {
      console.warn(`[${component} ${new Date().toISOString().slice(11, 19)}]`, ...args);
    },
    error: (...args: any[]) => {
      console.error(`[${component} ${new Date().toISOString().slice(11, 19)}]`, ...args);
    }
  };
};
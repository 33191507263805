import { gql } from '@apollo/client';

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

export const REGISTER_TOKEN_MUTATION = gql`
  mutation RegisterUserPushToken($pushToken: String!, $deviceId: String!, $platform: NotificationPlatform!) {
    registerUserPushToken(
      pushToken: $pushToken,
      deviceId: $deviceId,
      platform: $platform
    )
  }
`;
import restClient, {
  EnhancedRequestConfig,
} from "../../../services/restClient";

/**
 * Used to upload Chat assets (like images)
 *
 * @param chatId
 * @param file
 */
export const uploadChatImage = async (
  chatId: string,
  file: File,
  options?: {
    onProgress?: (progress: number) => void;
    signal?: AbortSignal;
  }
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  // Debug logging
  console.log("Uploading file:", {
    chatId,
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
  });

  try {
    const requestConfig: EnhancedRequestConfig = {};
    if (options?.signal) {
      requestConfig.signal = options.signal;
    }

    const uploadPath = `/upload/chat/${chatId}`;
    // Debug the request before it's sent
    console.log("Upload request config:", {
      url: uploadPath,
      formDataEntries: Array.from(formData.entries()).map((e) => [
        e[0],
        e[1] instanceof File ? e[1].name : e[1],
      ]),
      hasSignal: !!options?.signal,
    });

    const response = options?.onProgress
      ? await restClient.postWithProgress(uploadPath, formData, {
          ...requestConfig,
          onProgress: options.onProgress,
        })
      : await restClient.post(uploadPath, formData, requestConfig);

    if (response.status < 200 || response.status >= 300) {
      throw new Error("Failed to upload file");
    }

    const { destinationUrl } = response.data;
    return destinationUrl;
  } catch (error: any) {
    if (error.name === "CanceledError" || error.name === "AbortError") {
      throw error; // Re-throw for the caller to handle
    }

    // For Network errors (endpoint not found)
    if (error.code === "ERR_NETWORK") {
      const customError = new Error("Server endpoint not available");
      customError.name = "ServerError";
      throw customError;
    }

    // For actual server errors with response codes
    if (error.response) {
      const customError = new Error(`Server error: ${error.response.status}`);
      customError.name = "ServerError";
      throw customError;
    }

    // For all other errors
    const customError = new Error(error.message || "Failed to upload file");
    customError.name = "UploadError";
    throw customError;
  }
};

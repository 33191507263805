// Step 1: Install the package
// npm install browser-image-compression --save

// Step 2: Import in ChatInputArea.tsx
import imageCompression from 'browser-image-compression';

// Step 3: Add a compression function
export const compressImage = async (file: File): Promise<File> => {
  // Only compress images larger than 1MB
  if (file.size <= 1024 * 1024) {
    return file;
  }
  
  try {
    const options = {
      maxSizeMB: 1,              // Max size in MB
      maxWidthOrHeight: 1920,    // Max dimension
      useWebWorker: true,        // Use web workers for better performance
      preserveExif: true,        // Preserve image metadata if possible
      exifOrientation: 1,        // Force orientation to "normal" (1)
    };
    
    const compressedBlob = await imageCompression(file, options);
    
    // Convert the compressed blob back to a File with the original filename
    const compressedFile = new File(
      [compressedBlob],
      file.name,  // Preserve the original filename with extension
      { 
        type: compressedBlob.type,
        lastModified: new Date().getTime()
      }
    );
    
    console.log('Compression results:', { 
      originalSize: (file.size / 1024 / 1024).toFixed(2) + 'MB',
      compressedSize: (compressedFile.size / 1024 / 1024).toFixed(2) + 'MB',
      ratio: ((1 - compressedFile.size / file.size) * 100).toFixed(0) + '%'
    });
    
    return compressedFile;
  } catch (error) {
    console.error('Image compression failed:', error);
    // Fall back to original file if compression fails
    return file;
  }
};
import { EventModel } from "../models/eventModel";
import "./EventTicket.css";

function createEventDataString(event: EventModel) {
  // eventSubscribed query returns all events where the user is subscribed
  // and the subscriber data is filtered containing only the user data
  const meSubscriber = event.subscribers[0];

  const guestsString =
    meSubscriber.guests > 0 ? `${meSubscriber.guests} ospiti` : "nessun ospite";

  const childrenString =
    meSubscriber.childrenList.length > 0
      ? `\nporti i seguenti figliə\n${meSubscriber.childrenList
          .map((c) => `- ${c.name} (${c.age})`)
          .join("\n")}`
      : "nessun figlio";

  return `${guestsString}\n${childrenString}\n`;
}

export interface EventTicketProps {
  event: EventModel;
  qrCode: React.ReactNode;
}

export default function EventTicket({ event, qrCode }: EventTicketProps) {
  function longDateAndTime(date: Date) {
    const localDate = new Date(date);
    return `${localDate.toLocaleDateString("it-IT", {
      weekday: "long",
    })}, ${localDate.getDate()} ${localDate.toLocaleDateString("en-US", {
      month: "long",
    })} ${localDate.getFullYear()} ${localDate.toLocaleTimeString("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  function dateOnly(date: Date) {
    const localDate = new Date(date);
    return `${localDate.toLocaleDateString("it-IT", {
      weekday: "long",
    })}, ${localDate.getDate()} ${localDate.toLocaleDateString("en-US", {
      month: "long",
    })} ${localDate.getFullYear()}`;
  }

  function timeOnly(date: Date) {
    const localDate = new Date(date);
    return `${localDate.toLocaleTimeString("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  return (
    <div className="w-full">
      {/* Desktop view */}
      <div className="hidden xl:flex h-80 w-full shadow-md border rounded-md">
        {/* Left side - QR section (1/4 width) */}
        <div className="flex flex-col items-center justify-between w-1/4 h-full px-4 py-2 bg-white border-r-2 border-gray-500 border-dashed rounded-l-md">
          <div className="flex flex-col items-center">
            {/* <p className="my-1 text-xs italic font-light text-gray-500">
              Scansiona qui per il check-in!
            </p> */}
            {qrCode}
          </div>
        </div>

        {/* Right side - Image and details (3/4 width) */}
        <div className="relative w-3/4 h-full">
          {/* Image container */}
          <div className="absolute inset-0">
            <img
              src={event.images[0]}
              alt={event.title}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Overlay content */}
          <div className="absolute inset-0">
            {/* Organizer info */}
            <div className="absolute p-1 bottom-24">
              <div className="flex flex-row px-4 py-2 text-xs font-bold bg-white rounded-md">
                <span className="mr-2 font-normal text-gray-500">
                  Organizzatore
                </span>
                <p className="font-semibold main-text-color">
                  {event.owner?.name || "-"}
                </p>
              </div>
            </div>

            {/* Bottom details panel */}
            <div className="absolute bottom-0 w-full">
              {/* Semi-transparent background */}
              <div className="w-full h-28 bg-white/75 rounded-br-md" />

              {/* Content container */}
              <div className="absolute inset-x-0 bottom-0 p-4">
                <div className="flex flex-row justify-between space-x-4 overflow-hidden">
                  {/* Event details column */}
                  <div className="flex flex-col min-w-0">
                    <div className="flex flex-col mb-2">
                      <p className="text-xs text-gray-500">Evento:</p>
                      <a
                        href={`/events/${event.id}`}
                        className="font-semibold main-text-color truncate hover:text-blue-700 hover:underline transition-colors duration-200 flex items-center"
                      >
                        {event.title}
                        <svg
                          className="w-4 h-4 ml-1 inline-block"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="hidden md:flex flex-col">
                      <p className="text-xs text-gray-500">Quando:</p>
                      <p className="font-semibold main-text-color truncate">
                        {longDateAndTime(event.date)}
                        {event.endDate &&
                          ` - ${new Date(event.endDate).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`}
                      </p>
                    </div>
                  </div>

                  {/* Location and guests column */}
                  <div className="flex flex-col min-w-0">
                    <div className="hidden md:flex flex-col mb-2">
                      <p className="text-xs text-gray-500">Chi porti:</p>
                      <p className="font-semibold main-text-color truncate">
                        {createEventDataString(event)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-xs text-gray-500">Dove:</p>
                      <p className="font-semibold main-text-color truncate">
                        {event.location.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile view */}
      <div className="xl:hidden flex flex-col bg-white border rounded-md shadow-md">
        <div className="py-2 px-4 flex-col flex text-center">
          {qrCode}
          {/* <p className="font-bold text-lg md:text-3xl">
            Scansiona qui per il check-in!
          </p> */}
        </div>
        <hr className="border-dashed border-2 border-gray-400" />
        <img src={event.images[0]} alt={event.title} />
        <div className="py-2 px-4 flex flex-col text-sm md:text-2xl">
          <a
            href={`/events/${event.id}`}
            className="self-start font-bold text-black-500 text-blue-700 underline transition-colors duration-200 flex items-center group"
          >
            {event.title}
            <svg
              className="w-4 h-4 ml-1 opacity-100 transition-opacity duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
          <p className="self-start font-bold text-gray-500">Quando</p>
          <div className="flex text-sm justify-between my-2 md:text-xl">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              ></path>
            </svg>
            <p className="font-bold main-text-color">{dateOnly(event.date)}</p>
          </div>
          <div className="flex text-sm justify-between my-2 md:text-xl">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <p className="font-bold main-text-color">
              {timeOnly(event.date)}{" "}
              {event.endDate && " - " + timeOnly(event.endDate!)}
            </p>
          </div>
        </div>

        <div className="py-2 px-4 flex flex-col text-sm md:text-2xl">
          <p className="self-start font-bold text-gray-500">Dove</p>
          <div className="flex text-sm md:text-xl justify-between my-2">
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <p className="font-bold main-text-color">
              {event.location.description}
            </p>
          </div>
        </div>
        <hr className="border-gray-400" />
      </div>
    </div>
  );
}

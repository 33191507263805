import React, { useState, forwardRef } from "react";
import { MessageBubbleWrapper } from "./MessageBubbleWrapper";
import { ChatParticipant } from "../../models/chatParticipant";
import { Message } from "../../models/message";
import { ReplyContent } from "./ReplyContent";
import { useChatContext } from "../../context/ChatContext";
import { useReplyMessage } from "../../hooks/useReplyMessage";

interface ImageBubbleProps {
  message: Message;
  currentUserId: string;
  participants: ChatParticipant[];
}

export const ImageBubble = forwardRef<HTMLDivElement, ImageBubbleProps>(
  ({ message, currentUserId, participants }, ref) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [expandedView, setExpandedView] = useState(false);
    const { setReplyingTo } = useChatContext();

    const replyToMessage = useReplyMessage(message.replyTo, message.chatId);

    const handleReply = () => {
      setReplyingTo(message);
    };

    return (
      <MessageBubbleWrapper
        ref={ref}
        message={message}
        currentUserId={currentUserId}
        participants={participants}
        onReplyClick={handleReply}
        replyContent={
          replyToMessage && (
            <ReplyContent
              replyMessage={replyToMessage}
              currentUserId={currentUserId}
              participants={participants}
            />
          )
        }
        className="p-0 overflow-hidden"
      >
        {!imageLoaded && (
          <div className="w-48 h-48 flex items-center justify-center bg-gray-100">
            <div className="animate-pulse">Loading...</div>
          </div>
        )}
        <img
          src={
            message.content.mediaUrl || `${process.env.PUBLIC_URL}/no-image.png`
          }
          alt={message.content.text || ""}
          className={`max-w-full cursor-pointer transition-opacity ${
            imageLoaded ? "opacity-100" : "opacity-0 h-0"
          }`}
          style={{ maxHeight: expandedView ? "none" : "250px" }}
          onLoad={() => setImageLoaded(true)}
          onClick={() => setExpandedView(!expandedView)}
        />

        {/* Expanded overlay - keep this part as is */}
        {expandedView && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4"
            onClick={() => setExpandedView(false)}
          >
            <img
              src={
                message.content.mediaUrl ||
                `${process.env.PUBLIC_URL}/no-image.png`
              }
              alt="Expanded view"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        )}
      </MessageBubbleWrapper>
    );
  }
);

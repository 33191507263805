import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";

interface ToastContextType {
  showToast: (content: ReactNode) => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  const showToast = useCallback((content: ReactNode) => {
    setContent(content);
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="fixed top-0 left-0 right-0 flex justify-center z-50 pointer-events-none px-4 pt-2">
        <div
          className={`${
            show ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
          } transform transition-all duration-300 w-full max-w-sm bg-[rgba(255,255,255,0.98)] backdrop-blur-sm p-4 rounded-2xl shadow-lg`}
        >
          {content}
        </div>
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) throw new Error("useToast must be used within ToastProvider");
  return context;
};

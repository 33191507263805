import { ChatParticipant } from "../models/chatParticipant";

interface TypingBubbleProps {
  participant: ChatParticipant;
}

export const TypingBubble: React.FC<TypingBubbleProps> = ({ participant }) => (
  <div className="flex justify-start">
    <div className="flex items-end space-x-2 max-w-[70%]">
      <img 
        src={participant.avatarUrl} 
        alt="avatar" 
        className="w-8 h-8 rounded-full object-cover"
      />
      <div>
        <div className="rounded-lg px-4 py-2 bg-white border border-gray-200">
          <div className="flex space-x-1">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                style={{ 
                  animationDelay: `${i * 0.2}s`,
                  animationDuration: '1s'
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
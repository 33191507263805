import { createContext, useContext } from "react";
import { WebSocketMessage } from "./WebSocketMessage";

export enum ConnectionStatus {
  CONNECTING = "CONNECTING",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  ERROR = "ERROR",
}

export interface WebSocketContextType {
  sendTyping: (chatId: string, typing: boolean) => void;
  sendTextMessage: (
    chatId: string,
    message: string,
    existingTempId: string,
    replyToId?: string
  ) => string;
  sendImageMessage: (
    chatId: string,
    imageUrl: string,
    message: string | null,
    existingTempId: string,
    replyToId?: string
  ) => string;
  sendJoin: (chatId: string) => void;
  sendLeave: (chatId: string) => void;
  onMessage: (handler: (message: WebSocketMessage) => void) => void;
  sendReadReceipt: (chatId: string, messageIds: string[]) => void;

  removeMessageHandler: (handler: (message: WebSocketMessage) => void) => void;
  connectionStatus: ConnectionStatus;
  connect: () => void;
  disconnect: () => void;
}

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

import { gql } from "@apollo/client";
import { CHAT_FIELDS_FRAGMENT, MESSAGE_FIELDS_FRAGMENT } from "./fragments";

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      ...MessageFields
    }
  }
  ${MESSAGE_FIELDS_FRAGMENT}
`;

export const CREATE_DIRECT_CHAT_MUTATION = gql`
  mutation CreateDirectChat($recipientId: ID!) {
    createDirectChat(rcptID: $recipientId) {
      ...ChatFields
    }
  }
  ${CHAT_FIELDS_FRAGMENT}
`;
import React from "react";

type BadgeProps = {
  text: string;
  color?: string;
  position?: "top-right" | "top-left" | "bottom-right" | "bottom-left" | "diagonal";
  children: React.ReactNode;
};

const Badge: React.FC<BadgeProps> = ({ 
  text, 
  color = "bg-orange-500", 
  position = "diagonal", 
  children 
}) => {
  if (position === "diagonal") {
    return (
      <div className="relative inline-flex items-center">
        {children}
        <div className={`absolute -top-3 -right-3 transform rotate-12 ${color} text-white text-xs px-1 py-0.5 rounded-sm`}>
          {text}
        </div>
      </div>
    );
  }
  
  const positionClasses = {
    "top-right": "absolute -top-1 -right-1",
    "top-left": "absolute -top-1 -left-1",
    "bottom-right": "absolute -bottom-1 -right-1",
    "bottom-left": "absolute -bottom-1 -left-1",
  };
  
  return (
    <div className="relative inline-flex items-center">
      {children}
      <div className={`${positionClasses[position]} ${color} text-white text-xs px-1 py-0.5 rounded-sm`}>
        {text}
      </div>
    </div>
  );
};

export default Badge;
import { OwnerUser } from "../models/eventModel";
import { EnvelopeIcon, StarIcon } from "@heroicons/react/24/outline";
import { AlertCircle, Loader2, MessageSquareText, X } from "lucide-react";
import { CREATE_DIRECT_CHAT_MUTATION } from "../../chat/api/mutations";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../auth/hooks/useAuth";
import { userRoleAdmin } from "../../../consts";

export function EventOwnerCard({ owner }: { owner: OwnerUser }) {
  const navigate = useNavigate();
  const location = useLocation(); 

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { hasRole } = useAuth(); // Get hasRole function
  const isAdmin = hasRole(userRoleAdmin); // Check if user is admin


  const [createDirectChat, { loading }] = useMutation(
    CREATE_DIRECT_CHAT_MUTATION,
    {
      onCompleted: (data) => {
        if (data.createDirectChat) {
          setErrorMessage(null);
          // Navigate to the chat page with the newly created chat
          navigate(`/chats?chat_id=${data.createDirectChat.id}`, {
            state: { from: location.pathname }
          });
        }
      },
      onError: (error) => {
        setErrorMessage(
          error.message.includes("ERR_CANNOT_CREATE_CHAT_WITH_SELF")
            ? "Sei l'organizzatore della chat, puoi parlare con te stesso senza usare la chat 🙂."
            : "Impossibile creare la chat. Riprova più tardi."
        );
      },
    }
  );

  const handleCreateDirectChat = () => {
    // Make sure owner has the userId property
    if (owner.id) {
      createDirectChat({
        variables: {
          recipientId: owner.id,
        },
      });
    } else {
      console.error("Owner userId is missing");
    }
  };

  return (
    <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
      <div className="flex flex-1 flex-col p-8">
        <img
          alt=""
          src={owner.profileImage}
          className="mx-auto h-32 w-32 shrink-0 rounded-full object-cover"
        />
        <h3 className="mt-6 text-sm font-medium text-gray-900">{owner.name}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">Organizzatore</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <div className="inline-flex items-center">
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
              <StarIcon className="w-4 h-4 text-yellow-400 stroke-black stroke-1" />
            </div>
          </dd>
        </dl>
        {errorMessage && (
          <div className="mt-4 bg-red-50 border border-red-200 text-red-800 px-3 py-2 rounded-md text-sm flex items-start">
            <AlertCircle className="h-4 w-4 mt-0.5 mr-2 flex-shrink-0" />
            <span className="flex-1 text-left">{errorMessage}</span>
            <button
              onClick={() => setErrorMessage(null)}
              className="text-red-500 hover:text-red-700 ml-2"
            >
              <X size={16} />
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <div
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-400 cursor-not-allowed"
              title="Coming soon"
            >
              <EnvelopeIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
              <span className="flex flex-col items-center">
                <span>Scrivi</span>
                <span className="text-xs italic">coming soon</span>
              </span>
            </div>
          </div>
          <div className="-ml-px flex w-0 flex-1">
            <button
              onClick={handleCreateDirectChat}
              disabled={loading || !isAdmin}
              className={`relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold ${
                isAdmin ? "text-blue-600 hover:bg-gray-50" : "text-gray-400 cursor-not-allowed"
              } disabled:opacity-70 transition-colors`}
                       >
              {loading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <MessageSquareText aria-hidden="true" className="h-5 w-5" />
              )}
              <span className="flex flex-col items-center">
                <span>{loading ? "Creando chat..." : "Invia messaggio"}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

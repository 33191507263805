import { useCallback, useContext, useEffect, useState } from "react";
import { Chat } from "../models/chat";
import { VolumeOffIcon, VolumeUpIcon } from "hugeicons-react";
import {
  ConnectionStatus,
  WebSocketContext,
} from "../context/WebSocketContext";
import {
  deleteSharedValue,
  getSharedValue,
  setSharedValue,
} from "../../../services/sharedStore";
import ChatParticipants from "./ChatParticipants";
import { ArrowLeft, X } from "lucide-react";
import { createPortal } from "react-dom";

const ChatHeader: React.FC<{
  chat: Chat;
  onSelectChat?: (chat: Chat) => void;
  onBack?: () => void;
  isMobile?: boolean;
}> = ({ chat, onSelectChat, onBack, isMobile = false }) => {
  const [showParticipants, setShowParticipants] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const ws = useContext(WebSocketContext);

  useEffect(() => {
    getSharedValue(chat.id)
      .then((value) => setIsMuted(!!value))
      .catch((err) => {
        console.error("Error getting muted state:", err);
        setIsMuted(false);
      });
  }, [chat.id]);

  const renderConnectionStatus = () => {
    // Only render for non-connected states
    if (ws?.connectionStatus === ConnectionStatus.OPEN) return null;

    let bgColor = "bg-gray-400";
    let message = "Disconnesso";

    if (ws?.connectionStatus === ConnectionStatus.CONNECTING) {
      bgColor = "bg-yellow-500";
      message = "Riconnessione in corso...";
    } else if (ws?.connectionStatus === ConnectionStatus.ERROR) {
      bgColor = "bg-red-500";
      message = "Errore di connessione";
    }

    return (
      <div
        className={`absolute top-0 left-0 right-0 ${bgColor} text-white text-xs text-center py-1 z-20`}
      >
        {message}
      </div>
    );
  };

  const toggleMute = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    if (isMuted) {
      setIsMuted(false);
      deleteSharedValue(chat.id);
      console.log("Unmuting chat", chat.id);
    } else {
      setIsMuted(true);
      setSharedValue(chat.id, true.toString()); // Storing the chat ID to mute
      console.log("Muting chat", chat.id);
    }
    setShowMenu(false);
  };

  // Inside the ChatHeader component, add this memoized function
  const handleSelectChat = useCallback(
    (chat: Chat) => {
      onSelectChat?.(chat);
      setShowParticipants(false);
    },
    [onSelectChat, setShowParticipants]
  );

  return (
    <div
      className={`px-1 py-4 bg-white border-b border-gray-200 ${
        isMobile ? "sticky top-0 z-10" : ""
      }`}
    >
      {renderConnectionStatus()}
      <div className="flex items-center">
        {/* Mobile back button */}
        {isMobile && onBack && (
          <button
            onClick={onBack}
            className="p-0 mr-2 hover:bg-gray-100 rounded-full"
            aria-label="Back to chats list"
          >
            <ArrowLeft size={20} />
          </button>
        )}

        {/* Chat image */}
        <img
          src={chat.settings.image || ""}
          alt={chat.settings.title || ""}
          className="w-10 h-10 rounded-full"
        />

        {/* Chat title and info */}
        <div className="ml-3 flex-1 min-w-0">
          <h2 className="text-lg font-medium text-gray-900 truncate">
            {chat.settings.title}
          </h2>

          {/* Group participants */}
          {chat.type === "GROUP" && chat.participants.length > 0 && (
            <div className="flex items-center space-x-2">
              <p className="text-sm text-gray-500">
                {chat.participants.length} membri
              </p>
              <button
                onClick={() => setShowParticipants((prev) => !prev)}
                className="text-gray-500 hover:text-gray-700 transition"
              >
                {showParticipants ? (
                  // Up arrow
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                ) : (
                  // Down arrow
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                )}
              </button>
              {showParticipants &&
                createPortal(
                  <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 z-[9999]">
                    <div
                      className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[80vh] flex flex-col"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* Header with close button */}
                      <div className="p-4 border-b flex justify-between items-center">
                        <h2 className="text-lg font-semibold">Membri</h2>
                        <button
                          className="text-gray-400 hover:text-gray-600"
                          onClick={() => setShowParticipants(false)}
                        >
                          <X size={20} />
                        </button>
                      </div>

                      {/* Content - scrollable */}
                      <div className="flex-1 overflow-auto">
                        <ChatParticipants
                          participants={chat.participants}
                          onSelectChat={handleSelectChat}
                        />
                      </div>
                    </div>
                  </div>,
                  document.body
                )}
            </div>
          )}
        </div>

        {/* Right side: Connection indicator and three-dot menu */}
        <div className="ml-auto flex items-center space-x-2 relative">
          <button
            onClick={() => setShowMenu((prev) => !prev)}
            className="pl-2 text-gray-500 hover:text-gray-700 transition"
            aria-label="Chat options"
          >
            {/* Vertical ellipsis (three-dot) icon */}
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 5.5c.827 0 1.5-.673 1.5-1.5S12.827 2.5 12 2.5 10.5 3.173 10.5 4s.673 1.5 1.5 1.5zM12 13.5c.827 0 1.5-.672 1.5-1.5 0-.827-.673-1.5-1.5-1.5S10.5 12.173 10.5 13c0 .828.673 1.5 1.5 1.5zM12 21.5c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5-1.5.673-1.5 1.5.673 1.5 1.5 1.5z"
              />
            </svg>
          </button>
          {showMenu && (
            <div
              className="
                absolute right-0 top-8 w-40 bg-white border border-gray-200 
                shadow-md rounded-md z-10
              "
            >
              <ul className="py-1 text-sm text-gray-700">
                <li
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                  onClick={toggleMute}
                >
                  {isMuted ? (
                    <>
                      <VolumeUpIcon className="h-4 w-4" />
                      <span>Unmute</span>
                    </>
                  ) : (
                    <>
                      <VolumeOffIcon className="h-4 w-4" />
                      <span>Mute</span>
                    </>
                  )}
                </li>
                {/* <li
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                  onClick={() => {
                    console.log("Chat settings clicked");
                    setShowMenu(false);
                  }}
                >
                  <Cog6ToothIcon className="h-4 w-4" />
                  <span>Settings</span>
                </li> */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;

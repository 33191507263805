import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./features/auth/hooks/useAuth";


export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const LANDING_ROUTE = "/landing";
export const FORGOT_PWD_ROUTE = "/forgot-password";
export const PASSWORD_RECOVERY_ROUTE = "/password-recovery/:token";
export const EVENTS_ROUTE = "/events";
export const CALENDAR_ROUTE = "/calendar";
export const CREATE_EVENT_ROUTE = "/events/create";
export const UPDATE_EVENT_ROUTE = "/events/:eventId/edit";
export const WELCOME_ROUTE = "/welcome";
export const CONFIRM_MAIL_ROUTE = "/confirm/:confirmToken";
export const DONATION_ROUTE = "/donations";
export const SIGNOUT_ROUTE = "/signout";
export const PROFILE_ROUTE = "/profile";
export const ACCOUNT_ROUTE = "/profile/account";
export const MY_EVENTS_ROUTE = "/profile/myevents";
export const CHECKOUT_ROUTE = "/checkout";
export const PURCHASED_ROUTE = "/purchased";
export const COOKIE_POLICY_ROUTE = "/cookie-policy";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const CHATS_ROUTE = "/chats";
export const TERMS_ROUTE = "/terms";
export const FAQ_ROUTE = "/faq";

interface ProtectedRouteProps {
  component: React.ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    // Encode current path and append as redirectTo parameter
    const redirectUrl = `${LOGIN_ROUTE}?redirectTo=${encodeURIComponent(
      location.pathname
    )}`;
    return <Navigate to={redirectUrl} />;
  }

  return <Component />;
};

// export const PublicRoutes = () => (
//   <>
//     <Route
//       path={LANDING_ROUTE}
//       element={
//         <PublicLayout>
//           <Landing />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={WELCOME_ROUTE}
//       element={
//         <PublicLayout>
//           <Welcome />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={COOKIE_POLICY_ROUTE}
//       element={
//         <PublicLayout>
//           <CookiePolicy />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={FAQ_ROUTE}
//       element={
//         <PublicLayout>
//           <FAQ />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={PRIVACY_POLICY_ROUTE}
//       element={
//         <PublicLayout>
//           <PrivacyPolicy />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={TERMS_ROUTE}
//       element={
//         <PublicLayout>
//           <TermsOfUse />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={CONFIRM_MAIL_ROUTE}
//       element={
//         <PublicLayout>
//           <ConfirmEmail />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={DONATION_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <Donations />
//             </PublicLayout>
//           )}
//         />
//       }
//     />
//   </>
// );

// export const AuthRoutes = () => (
//   <>
//     <Route path={SIGNOUT_ROUTE} element={<Logout />} />
//     <Route
//       path={LOGIN_ROUTE}
//       element={
//         <AuthLayout>
//           <SignIn />
//         </AuthLayout>
//       }
//     />
//     <Route
//       path={SIGNUP_ROUTE}
//       element={
//         <AuthLayout>
//           <Signup />
//         </AuthLayout>
//       }
//     />
//     <Route
//       path={FORGOT_PWD_ROUTE}
//       element={
//         <AuthLayout>
//           <ForgotPassword />
//         </AuthLayout>
//       }
//     />
//     <Route
//       path={PASSWORD_RECOVERY_ROUTE}
//       element={
//         <AuthLayout>
//           <PasswordRecovery />
//         </AuthLayout>
//       }
//     />
//   </>
// );

// // NOTE: Some routes here works both for public and authenticated users
// export const ProtectedRoutes = () => (
//   <>
//     <Route
//       path={EVENTS_ROUTE}
//       element={
//         <PublicLayout>
//           <Events />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={`${EVENTS_ROUTE}/:eventId`}
//       element={
//         <PublicLayout>
//           <Event />
//         </PublicLayout>
//       }
//     />
//     <Route
//       path={CALENDAR_ROUTE}
//       element={
//         <PublicLayout>
//           <Calendar />
//         </PublicLayout>
//       }
//     />

//     <Route
//       path={CREATE_EVENT_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <NewEvent />
//             </PublicLayout>
//           )}
//         />
//       }
//     />

//     <Route
//       path={UPDATE_EVENT_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <NewEvent />
//             </PublicLayout>
//           )}
//         />
//       }
//     />

//     <Route
//       path={PROFILE_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <Profile />
//             </PublicLayout>
//           )}
//         />
//       }
//     />

//     <Route
//       path={CHATS_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <ChatLayout />
//             </PublicLayout>
//           )}
//         />
//       }
//     />

//     <Route
//       path={MY_EVENTS_ROUTE}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <MyEvents />
//             </PublicLayout>
//           )}
//         />
//       }
//     />

//     <Route
//       path={`${CHECKOUT_ROUTE}/:eventId`}
//       element={
//         <ProtectedRoute
//           component={() => (
//             <PublicLayout>
//               <Checkout />
//             </PublicLayout>
//           )}
//         />
//       }
//     />
//   </>
// );

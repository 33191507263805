export const isIos = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches || 
  (window.navigator as any).standalone;
};

export const isSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /safari/.test(userAgent) && !/chrome/.test(userAgent);
};

const DEVICE_ID_KEY = 'gj_device_id';

export const getOrCreateDeviceId = (): string => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};

export type NotificationPlatform = 'web' | 'ios_web' | 'android_web';

export const getPlatform = (): NotificationPlatform => {
  const userAgent = navigator.userAgent.toLowerCase();
  
  if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'ios_web';
  }
  if (/android/.test(userAgent)) {
    return 'android_web';
  }
  return 'web';
};
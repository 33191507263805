import { useApolloClient } from "@apollo/client";
import { useContext } from "react";
import { WebSocketContext } from "../context/WebSocketContext";
import useFileUpload from "../../../hooks/useFileUpload";
import { uploadChatImage } from "../api/chatService";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";

// Generate a unique ID for each upload
const generateTempId = () =>
  `temp-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

export function useChatImageUpload(chatId: string, currentUserId?: string) {
  const client = useApolloClient();
  const ws = useContext(WebSocketContext);

  // Use our new generic file upload hook
  const {
    upload,
    cancelUpload,
    retryFailedUpload: retryUpload,
    isUploading,
    progress,
    uploadId,
    previewUrl,
  } = useFileUpload(chatId, uploadChatImage, {
    context: "chat",
    compressImage: true,
    onSuccess: (mediaUrl) => {
      console.log("Upload success in callback with URL:", mediaUrl);
      // No action needed here - we handle this in uploadImage
    },
    onError: (error) => {
      console.log("Upload failed in callback:", error);
      // No action needed here - we handle this in uploadImage
    },
  });

  // Upload a new image
  const uploadImage = async (file: File) => {
    if (!file) return;

    // Generate temp ID for this upload
    const sharedId = generateTempId();

    // Create optimistic message first
    addOptimisticUploadingMessage(sharedId, chatId, currentUserId);

    const currentUploadData = { messageId: sharedId };

    try {
      const result = await upload(file, sharedId);
      if (result) {
        console.log(
          "Upload completed, updating message:",
          currentUploadData.messageId
        );
        updateMessageToComplete(currentUploadData.messageId, result);
        ws?.sendImageMessage(chatId, result, null, sharedId);
      }

      return result;
    } catch (error) {
      console.error("Chat image upload failed:", error);
      // Update the failed message with the correct ID
      updateMessageToFailed(sharedId);
    }
  };

  // Retry a failed upload
  const retryFailedUpload = async (messageId: string, file: File) => {
    // Update UI to show uploading again
    updateMessageToUploading(messageId);

    try {
      // Use the generic retry function
      await retryUpload(file);
    } catch (error) {
      console.error("Retry upload failed:", error);
      // Error handling is already done in the onError callback
    }
  };

  // Cancel the current upload
  const cancelImageUpload = (messageId: string) => {
    cancelUpload();
    removeMessageFromCache(messageId);
  };

  // Apollo cache manipulation functions
  const addOptimisticUploadingMessage = (
    uploadId: string,
    chatId: string,
    senderId?: string
  ) => {
    const now = new Date().toISOString();
    const uploadingMessage = {
      __typename: "Message",
      id: uploadId,
      createdAt: now,
      updatedAt: now,
      chatId: chatId,
      senderId: senderId,
      content: {
        __typename: "MessageContent",
        type: "UPLOADING_IMAGE",
        text: "",
        mediaUrl: "",
        pollData: null,
        linkPreview: null,
      },
      replyTo: "000000000000000000000000",
      status: "sending",
      readBy: [],
      reactions: [],
    };

    // Add placeholder to cache
    client.cache.modify({
      fields: {
        messages(existingMessages = {}, { readField, toReference }) {
          // Only update messages for this specific chat
          const messageChatId = readField("chatId", existingMessages);

          // Important - skip if this is for a different chat
          if (messageChatId && messageChatId !== chatId) {
            return existingMessages;
          }

          const newEdge = {
            __typename: "MessageEdge",
            cursor: uploadId,
            node: client.cache.writeFragment({
              data: uploadingMessage,
              fragment: MESSAGE_FIELDS_FRAGMENT,
            }),
          };

          return {
            ...existingMessages,
            totalCount: existingMessages.totalCount + 1,
            edges: [newEdge, ...existingMessages.edges],
          };
        },
      },
    });
  };

  // Helper function to update message in cache
  const updateMessageInCache = (
    messageId: string,
    contentUpdate: any,
    statusUpdate: string,
    logMessage: string
  ) => {
    console.log(logMessage);

    try {
      // First try direct approach
      client.cache.modify({
        id: messageId, // Use raw messageId
        fields: {
          content() {
            return contentUpdate;
          },
          status() {
            return statusUpdate;
          },
        },
      });

      // Force cache consistency
      client.cache.gc();

      console.log(`Modified message ${messageId} directly in cache`);
    } catch (e) {
      console.error(`Error updating message ${messageId} directly:`, e);

      // Fall back to messages field update
      client.cache.modify({
        fields: {
          messages(existingMessages = {}, { readField }) {
            if (!existingMessages.edges) return existingMessages;

            // Filter by chat ID - CRITICAL FIX!
            const messageChatId = readField("chatId", existingMessages);
            if (messageChatId && messageChatId !== chatId) {
              return existingMessages;
            }

            // Find and replace the message node content
            const newEdges = existingMessages.edges.map((edge: any) => {
              const nodeId = readField("id", edge.node);

              if (nodeId === messageId) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    content: contentUpdate,
                    status: statusUpdate,
                  },
                };
              }
              return edge;
            });

            return {
              ...existingMessages,
              edges: newEdges,
            };
          },
        },
      });

      console.log(`Modified message ${messageId} through messages field`);
    }
  };

  const updateMessageToComplete = (messageId: string, mediaUrl: string) => {
    updateMessageInCache(
      messageId,
      {
        __typename: "MessageContent",
        type: "IMAGE",
        text: "",
        mediaUrl,
        pollData: null,
        linkPreview: null,
      },
      "sent",
      `Updating message ${messageId} to complete state`
    );
  };

  const updateMessageToFailed = (messageId: string) => {
    updateMessageInCache(
      messageId,
      {
        __typename: "MessageContent",
        type: "FAILED_IMAGE",
        text: "Upload failed. Tap to retry.",
        mediaUrl: "",
        pollData: null,
        linkPreview: null,
      },
      "failed",
      `Updating message ${messageId} to failed state`
    );
  };

  const updateMessageToUploading = (messageId: string) => {
    updateMessageInCache(
      messageId,
      {
        __typename: "MessageContent",
        type: "UPLOADING_IMAGE",
        text: "",
        mediaUrl: "",
        pollData: null,
        linkPreview: null,
      },
      "sending",
      `Updating message ${messageId} to uploading state`
    );
  };

  const removeMessageFromCache = (messageId: string) => {
    client.cache.modify({
      fields: {
        messages(existingMessages = {}, { readField }) {
          // Only remove from the correct chat
          const messageChatId = readField("chatId", existingMessages);
          if (messageChatId && messageChatId !== chatId) {
            return existingMessages;
          }

          return {
            ...existingMessages,
            edges: existingMessages.edges.filter(
              (edge: any) => readField("id", edge.node) !== messageId
            ),
            totalCount: Math.max(0, existingMessages.totalCount - 1),
          };
        },
      },
    });
  };

  return {
    uploadImage,
    retryFailedUpload,
    cancelUpload: cancelImageUpload,
    isUploading,
    progress,
    uploadId,
    previewUrl,
  };
}

import React from 'react';
import { X, Image as ImageIcon } from 'lucide-react';
import { Message } from '../models/message';

interface ReplyPreviewProps {
  message: Message;
  onCancel: () => void;
}

const ReplyPreview: React.FC<ReplyPreviewProps> = ({ message, onCancel }) => {
  const isImageMessage = message.content.type === 'IMAGE';
  
  return (
    <div className="p-2 bg-gray-50 border-b border-gray-100">
      <div className="flex items-center">
        {/* WhatsApp-style vertical bar */}
        <div className="w-1 self-stretch bg-green-500 mr-2"></div>
        
        <div className="flex-1 min-w-0">
          {/* "Replying to" text */}
          <p className="text-xs font-medium text-green-600 mb-0.5">
            Replying to message
          </p>
          
          {/* Message content preview */}
          <div className="flex items-center">
            {isImageMessage && message.content.mediaUrl && (
              <div className="w-10 h-10 rounded-sm overflow-hidden mr-2 flex-shrink-0 bg-gray-100">
                <img 
                  src={message.content.mediaUrl}
                  alt=""
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = `${process.env.PUBLIC_URL}/no-image.png`;
                  }}
                />
              </div>
            )}
            
            {isImageMessage && !message.content.mediaUrl && (
              <div className="w-10 h-10 rounded-sm flex items-center justify-center bg-gray-100 mr-2 flex-shrink-0">
                <ImageIcon size={16} className="text-gray-400" />
              </div>
            )}
            
            <p className="text-sm text-gray-600 truncate">
              {isImageMessage && !message.content.text 
                ? "Photo" 
                : message.content.text || "[messaggio mancante]"}
            </p>
          </div>
        </div>
        
        {/* Close button */}
        <button 
          onClick={onCancel}
          className="p-1 rounded-full hover:bg-gray-200 transition-colors"
          aria-label="Cancel reply"
        >
          <X size={18} className="text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default ReplyPreview;
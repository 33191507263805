import { gql } from "@apollo/client";

export const CHAT_FIELDS_FRAGMENT = gql`
  fragment ChatFields on Chat {
    id
    createdAt
    updatedAt
    type
    blindDateSession
    __typename
    participants {
      userId
      displayName
      avatarUrl
      joinedAt
      role
      __typename
    }
    settings {
      title
      image
      isModerated
      showFullHistory
      __typename
      permissions {
        addMembers
        approveMembers
        sendMessages
        __typename
      }
    }
    lastMessage {
      id
      content
      sentAt
      senderId
      __typename
    }
  }
`;

export const MESSAGE_FIELDS_FRAGMENT = gql`
  fragment MessageFields on Message {
    __typename
    id
    chatId
    senderId
    replyTo
    createdAt
    updatedAt
    reactions {
        emoji
        userIds
    }
    content {
      __typename
      type
      text
      mediaUrl
      pollData {
          question
          options
          endDate
          votes {
              optionId
              voters
          }
      }
    }
    readBy {  
      userId
      readAt
    }
  }
`;
import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import config from "../config/config";
import {
  getOrCreateDeviceId,
  getPlatform,
  isInStandaloneMode,
  isIos,
} from "../services/mobileUtils";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useMutation } from "@apollo/client";
import { REGISTER_TOKEN_MUTATION } from "../features/user/api/mutations";

const FCM_TOKEN_KEY = "gj_fcm_token";

export const getStoredToken = () => localStorage.getItem(FCM_TOKEN_KEY);
export const setStoredToken = (token: string) =>
  localStorage.setItem(FCM_TOKEN_KEY, token);

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
};

const isServiceWorkerSupported = () => {
  return "serviceWorker" in navigator;
};

let messaging: firebase.messaging.Messaging | null = null;

if (isServiceWorkerSupported()) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
}

interface NotificationData {
  title: string;
  body: string;
  image: string | null;
  chatId?: string;
  clickAction?: string;
  identityId?: string;
}

export const useFirebaseMessaging = () => {
  const { isAuthenticated } = useAuth();
  const [registerToken] = useMutation(REGISTER_TOKEN_MUTATION);
  const [notification, setNotification] = useState<NotificationData | null>(null);

  const requestPushPermission = useCallback(async () => {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications");
      return false;
    }

    // Check if we're in a secure context
    if (!window.isSecureContext) {
      console.error(
        "Notifications require a secure context (HTTPS or localhost)"
      );
      return false;
    }

    if (!messaging || !isServiceWorkerSupported()) {
      console.error("Push notifications are not supported in this browser");
      return false;
    }

    if (isIos() && !isInStandaloneMode()) {
      console.log(
        "iOS requires adding the website to Home Screen for push notifications"
      );
      return false;
    }

    try {
      const currentPermission = Notification.permission;

      if (currentPermission === "denied") {
        return {
          canRequest: false,
          needsSettingsReset: true,
          browserInstructions: `
            Per attivare le notifiche:
            1. Clicca sull'icona del lucchetto nella barra degli indirizzi
            2. Clicca su "Impostazioni sito"
            3. Trova "Notifiche" e cambia da "Blocca" a "Chiedi"
            4. Ricarica la pagina
          `,
        };
      }

      const permission = await Notification.requestPermission();
      console.log("Permission", permission);
      if (permission === "granted") {
        const newToken = await messaging?.getToken({
          vapidKey: config.firebase.vapidKey,
        });

        if (!newToken) return false;

        const storedToken = getStoredToken();
        if (storedToken === newToken) {
          console.log("Token already registered");
          return true;
        }

        const deviceId = getOrCreateDeviceId();
        const platform = getPlatform();

        await registerToken({
          variables: {
            pushToken: newToken,
            deviceId,
            platform,
          },
        });

        setStoredToken(newToken);
        console.log("New FCM Token registered:", newToken);
        return true;
      }

      return false;
    } catch (error) {
      console.error("Permission denied", error);
      return false;
    }
  }, [registerToken]);

  useEffect(() => {
    if (!isAuthenticated || !messaging) return;

    // Extract token check logic
    const checkAndUpdateToken = async () => {
      try {
        // First check if we have permission
        if (Notification.permission !== "granted") {
          console.log(
            "Notification permission not granted, skipping token check"
          );
          return;
        }

        const currentToken = await messaging?.getToken({
          vapidKey: config.firebase.vapidKey,
        });
        const storedToken = getStoredToken();

        if (currentToken && currentToken !== storedToken) {
          console.log("Token changed, updating...");
          const deviceId = getOrCreateDeviceId();
          const platform = getPlatform();

          await registerToken({
            variables: {
              pushToken: currentToken,
              deviceId,
              platform,
            },
          });

          setStoredToken(currentToken);
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };

    // Foreground message handler
    const messageUnsubscribe = messaging.onMessage((payload) => {
      setNotification({
        title: payload.data?.title || "GioJoy",
        body: payload.data?.body || "",
        image: payload.data?.image || null,
        chatId: payload.data?.chatId || undefined,
        clickAction: payload.data?.click_action || undefined,
        identityId: payload.data?.identityId || undefined,
      });
    });

    // Check token immediately and then set up interval
    checkAndUpdateToken();
    const tokenCheckInterval = setInterval(checkAndUpdateToken, 1000 * 60 * 60);

    // Cleanup
    return () => {
      messageUnsubscribe();
      clearInterval(tokenCheckInterval);
    };
  }, [isAuthenticated, registerToken]);

  return { notification, requestPushPermission };
};

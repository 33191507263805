import { useCallback, useEffect, useRef, useState } from "react";
import { MessageEdge } from "../models/messageEdge";
import { MessagesResponse } from "../models/messageResponse";
import { messageManager } from "../services/msgManager";

interface UseChatSyncProps {
  chatId: string;
  messages: MessageEdge[];
  fetchMore: (options: any) => Promise<any>;
  refetch: (variables?: any) => Promise<any>;
}

export const useChatSync = ({
  chatId,
  messages,
  fetchMore,
  refetch,
}: UseChatSyncProps) => {
  // Simple state tracking
  const [isSyncing, setIsSyncing] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

   // Track last sync by chatId to prevent loops
   const lastSyncTimeRef = useRef<Record<string, number>>({});
   const isSyncingRef = useRef(false); // Add ref to track syncing state without re-renders
   
  // Essential refs
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

   // Track if this is first mount
   const isFirstMountRef = useRef<Record<string, boolean>>({});

  // ---------- SCROLL FUNCTIONS ----------
  const isNearBottom = useCallback(() => {
    const container = messagesContainerRef.current;
    if (!container) return false;
    return (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      100
    );
  }, []);

  const handleScroll = useCallback(() => {
    setShouldScrollToBottom(isNearBottom());
  }, [isNearBottom]);

  const scrollToBottom = useCallback(() => {
    if (!messagesEndRef.current) return;
    const behavior = isSyncing ? "auto" : "smooth";
    messagesEndRef.current.scrollIntoView({ behavior });
  }, [isSyncing]);

  // ---------- SYNC FUNCTION ----------
  // This is the core of our simplification - one reliable sync function
  const syncMessages = useCallback(async () => {
 if (isSyncingRef.current || !chatId) return;

    console.log(`Syncing messages for chat ${chatId}`);
     // Add protection against rapid syncs (key change)
     const now = Date.now();
     const lastSync = lastSyncTimeRef.current[chatId] || 0;
     if (now - lastSync < 1000) { // Don't sync same chat more than once per second
       console.log(`Skipping sync for ${chatId} - too soon after last sync`);
       return;
     }
    
     
    setIsSyncing(true);
    isSyncingRef.current = true;
    lastSyncTimeRef.current[chatId] = now;

    try {
      // Step 1: Always do a basic refetch first for UI responsiveness
      await refetch({ chatId, limit: 30 });

      // Step 2: Check if we need to fetch missed messages
      const lastKnownMessageId = messageManager.getLastKnownMessageId(chatId);
      if (lastKnownMessageId && !lastKnownMessageId.startsWith("temp-")) {
        console.log(`Fetching any missed messages since ${lastKnownMessageId}`);

        // Fetch messages we might have missed
        await fetchMore({
          variables: {
            chatId,
            cursor: lastKnownMessageId,
            limit: 50,
            sortDirection: "ASC",
          },
          updateQuery: (
            prev: any,
            { fetchMoreResult }: { fetchMoreResult?: MessagesResponse }
          ) => {
            // Skip if no results
            if (!fetchMoreResult || !fetchMoreResult.messages.edges.length) {
              return prev;
            }

            // Filter out duplicates
            const existingIds = new Set(
              prev.messages.edges.map((edge: MessageEdge) => edge.node.id)
            );

            const newEdges = fetchMoreResult.messages.edges.filter(
              (edge: MessageEdge) => !existingIds.has(edge.node.id)
            );

            if (!newEdges.length) return prev;

            console.log(`Found ${newEdges.length} missed messages`);

            // Sort for chronological order
            const sortedNewEdges = [...newEdges].sort((a, b) => {
              return (
                new Date(b.node.createdAt).getTime() -
                new Date(a.node.createdAt).getTime()
              );
            });

            // Return updated data
            return {
              messages: {
                ...prev.messages,
                edges: [...sortedNewEdges, ...prev.messages.edges],
                totalCount: prev.messages.totalCount + newEdges.length,
              },
            };
          },
        });
      }

      // Always scroll to bottom after sync
      setTimeout(scrollToBottom, 100);
    } catch (error) {
      console.error("Error syncing messages:", error);
    } finally {
      setIsSyncing(false);
      isSyncingRef.current = false;
    }
  }, [chatId, refetch, fetchMore, scrollToBottom]);

  // ---------- EFFECTS ----------

  // Track latest seen message for each chat
  useEffect(() => {
    if (messages?.length > 0) {
      // Find the most recent non-temporary message
      const validMessages = messages.filter(
        (edge) => !edge.node.id.startsWith("temp-")
      );

      if (validMessages.length > 0) {
        // Sort by timestamp to get the latest
        const sortedMessages = [...validMessages].sort((a, b) => {
          return (
            new Date(b.node.createdAt).getTime() -
            new Date(a.node.createdAt).getTime()
          );
        });

        const latestMessage = sortedMessages[0];
        messageManager.trackLatestMessage(chatId, latestMessage.node.id);
      }
    }
  }, [messages, chatId]);

  // Sync when chat changes
  useEffect(() => {
    if (!chatId) return;
    
    // Only sync on first render of a specific chat
    if (!isFirstMountRef.current[chatId]) {
      isFirstMountRef.current[chatId] = true;
      syncMessages();
    }
    
  }, [chatId, syncMessages]);

  useEffect(() => {
    const currentChatId = chatId;
    const firstMountObj = isFirstMountRef.current;
    return () => {
      if (chatId) {
        firstMountObj[currentChatId] = false;
      }
    };
  }, [chatId]);

  
  // Sync when visibility changes (tab becomes active)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && chatId) {
        // Only sync if it's been a while since last sync
        const now = Date.now();
        const lastSync = lastSyncTimeRef.current[chatId] || 0;
        if (now - lastSync > 2000) { // 2 second minimum between visibility syncs
          syncMessages();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [chatId, syncMessages]);

  // Auto-scroll on new messages
  useEffect(() => {
    if (messages.length > 0 && shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [messages.length, shouldScrollToBottom, scrollToBottom]);

  return {
    syncMessages,
    scrollToBottom,
    messagesEndRef,
    loadMoreRef,
    messagesContainerRef,
    shouldScrollToBottom,
    handleScroll,
    isSyncing,
  };
};

import { useState, useRef, useCallback, useEffect } from "react";
import { useFileUploadContext } from "./FileUploadContext";
import { compressImage } from "../services/fileCompressionService";

// Generate a unique ID for each upload
const generateUploadId = () =>
  `upload-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

// Type for the upload function
export type UploadFunction = (
  id: string,
  file: File,
  options?: {
    onProgress?: (progress: number) => void;
    signal?: AbortSignal;
  }
) => Promise<string>;

// Options for the hook
interface UseFileUploadOptions {
  context?: string;
  compressImage?: boolean;
  onSuccess?: (url: string) => void;
  onError?: (error: Error) => void;
  onProgress?: (progress: number) => void;
  onCancel?: () => void;
}

// Return type for the hook
interface UseFileUploadResult {
  // Methods
  upload: (file: File, customUploadId?: string) => Promise<string | null>;
  cancelUpload: () => void;
  retryFailedUpload: (file: File) => Promise<string | null>;

  // State
  uploadId: string | null;
  isUploading: boolean;
  progress: number;
  uploadedUrl: string | null;
  previewUrl: string | null;
  error: Error | null;
}

export default function useFileUpload(
  resourceId: string,
  uploadFn: UploadFunction,
  options: UseFileUploadOptions = {}
): UseFileUploadResult {
  const {
    context = "default",
    compressImage: shouldCompress = false,
    onSuccess,
    onError,
    onProgress,
    onCancel,
  } = options;

  const {
    startUpload,
    updateUploadProgress,
    completeUpload,
    cancelUpload: contextCancelUpload,
    getUpload,
  } = useFileUploadContext();

  // Local state
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  // Refs
  const abortControllerRef = useRef<AbortController | null>(null);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Update local state when context state changes
  useEffect(() => {
    if (!uploadId) return;

    const upload = getUpload(uploadId);
    if (!upload) return;

    setProgress(upload.progress);
    setPreviewUrl(upload.previewUrl);

    switch (upload.status) {
      case "uploading":
        setIsUploading(true);
        break;
      case "success":
        setIsUploading(false);
        break;
      case "error":
        setIsUploading(false);
        setError(upload.error || new Error("Upload failed"));
        break;
      case "cancelled":
        setIsUploading(false);
        break;
      default:
        break;
    }
  }, [uploadId, getUpload]);

  const uploadIdRef = useRef<string | null>(null);

  useEffect(() => {
    uploadIdRef.current = uploadId;
  }, [uploadId]);

  const upload = useCallback(
    async (file: File, customUploadId?: string): Promise<string | null> => {
      if (!file) return null;

      try {
        setIsUploading(true);
        setError(null);
        setProgress(0);

        const newUploadId = customUploadId || generateUploadId();
        setUploadId(newUploadId);
  

        // Register upload in context
        await startUpload(newUploadId, file, context);

        // Create new abort controller
        abortControllerRef.current = new AbortController();

        // Compress the image if needed
        let fileToUpload = file;
        if (shouldCompress && file.type.startsWith("image/")) {
          fileToUpload = await compressImage(file);
        }

        // Call the upload function
        const url = await uploadFn(resourceId, fileToUpload, {
          onProgress: (percent) => {
            setProgress(percent);
            updateUploadProgress(newUploadId, percent);
            onProgress?.(percent);
          },
          signal: abortControllerRef.current.signal,
        });

        // Update state on success
        setUploadedUrl(url);
        setIsUploading(false);
        completeUpload(newUploadId, true);
        onSuccess?.(url);

        return url;
      } catch (err: any) {
        // Handle errors
        const error = err instanceof Error ? err : new Error(String(err));

        // Don't treat cancellations as errors in the UI
        const isCancelled =
          error.name === "AbortError" || error.name === "CanceledError";

        if (!isCancelled) {
          setError(error);
          onError?.(error);
        }

        setIsUploading(false);
        completeUpload(uploadIdRef.current!, false, error);

        // Re-throw so caller can handle it
        throw error;
      }
    },
    [
      resourceId,
      uploadFn,
      context,
      startUpload,
      updateUploadProgress,
      completeUpload,
      onProgress,
      onSuccess,
      onError,
      shouldCompress, // Add this but not uploadId
    ]
  );

  const cancelUpload = useCallback(() => {
    if (uploadId && isUploading) {
      contextCancelUpload(uploadId);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }

      setIsUploading(false);
      onCancel?.();
    }
  }, [uploadId, isUploading, contextCancelUpload, onCancel]);

  // Retry failed upload
  const retryFailedUpload = useCallback(
    async (file: File): Promise<string | null> => {
      return upload(file);
    },
    [upload]
  );

  return {
    upload,
    cancelUpload,
    retryFailedUpload,
    uploadId,
    isUploading,
    progress,
    uploadedUrl,
    previewUrl,
    error,
  };
}

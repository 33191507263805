// This optional code is used to register a service worker.
// register() is not called by default.

import { isInStandaloneMode, isIos } from "./services/mobileUtils";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onError?: (error: Error) => void;
};

export async function register(config?: Config) {
  if (isIos() && !isInStandaloneMode()) {
    console.log(
      "iOS requires adding the website to Home Screen for push notifications"
    );
    return;
  }

  if (!("serviceWorker" in navigator)) {
    console.log("Service Worker not supported");
    return;
  }

  if (process.env.NODE_ENV === "production") {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    try {
      await new Promise((resolve) => window.addEventListener("load", resolve));

      const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;

      if (isLocalhost) {
        // Development mode - validate SW
        const response = await fetch(swUrl, {
          headers: { "Service-Worker": "script" },
        });

        const contentType = response.headers.get("content-type");
        if (
          response.status === 404 ||
          (contentType && !contentType.includes("javascript"))
        ) {
          console.warn("SERVICE WORKER: Not found or invalid content type");
          return;
        }
      }

      // Check for existing registration first
      const existingRegistration =
        await navigator.serviceWorker.getRegistration(swUrl);
      if (existingRegistration) {
        console.log("Updating existing Service Worker...");
        await existingRegistration.update();
        handleServiceWorkerUpdate(existingRegistration, config);
        return existingRegistration;
      }

      // Register or update SW
      const registration = await navigator.serviceWorker.register(swUrl, {
        scope: "/",
        updateViaCache: "none", // Prevent caching issues
      });

      handleServiceWorkerUpdate(registration, config);
      
      // Android Chrome update handling - use longer interval
      if (/Android.*Chrome/.test(navigator.userAgent)) {
        setInterval(async () => {
          try {
            await registration.update();
          } catch (error) {
            console.error("Error updating service worker:", error);
          }
        }, 1000 * 60 * 60); // Change to hourly updates
      }

      return registration;
    } catch (error) {
      console.error("Error during service worker registration:", error);
      config?.onError?.(
        error instanceof Error ? error : new Error(String(error))
      );
    }
  }
}

function handleServiceWorkerUpdate(
  registration: ServiceWorkerRegistration,
  config?: Config
) {
  registration.addEventListener("updatefound", () => {
    const installingWorker = registration.installing;
    if (!installingWorker) return;

    installingWorker.addEventListener("statechange", () => {
      if (installingWorker.state === "installed") {
        if (navigator.serviceWorker.controller) {
          console.log("New service worker version available");
          if (registration.waiting) {
            // Ensure only one update activation occurs
            const waitingWorker = registration.waiting;
            if (waitingWorker) {
              waitingWorker.addEventListener("statechange", (event) => {
                if ((event.target as ServiceWorker).state === "activated") {
                  // Reload only once after activation
                  window.location.reload();
                }
              });
              waitingWorker.postMessage({ type: "SKIP_WAITING" });
            }
          }
        } else {
          console.log("Service Worker installed");
          config?.onSuccess?.(registration);
        }
      }
    });
  });
}

export async function unregister() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
    } catch (error) {
      console.error("Error during service worker unregistration:", error);
    }
  }
}

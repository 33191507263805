import React from "react";
import { MY_EVENTS_QUERY } from "../api/eventService";
import { useQuery } from "@apollo/client";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { EventModel } from "../models/eventModel";
import EventTicket from "../components/EventTicket";
import { QRNormal } from "react-qrbtf";
import { Link } from "react-router-dom";
import { EmptyMyEventsIcon } from "../../../components/EmptyMyEventsIcon";

export default function MyEvents() {
  const { data, error, loading } = useQuery(MY_EVENTS_QUERY, {
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel message={error.message} />;
  }

  if (data.userEvents.length === 0) {
    return (
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 text-center">
        <h1 className="text-3xl font-semibold text-gray-900">
          Non sei iscritto ad alcun evento.
        </h1>
        <p className="mt-2 text-sm text-gray-700">
          Iscriviti a un evento per iniziare!
        </p>
        <div className="flex justify-center m-5">
          <EmptyMyEventsIcon />
        </div>
        <div className="mt-4">
          <Link
            to="/events" // Adjust the path to your events listing page
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Iscriviti ad un evento
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">Eventi</h1>
          <p className="mt-2 text-sm text-gray-700">
            Qui puoi vedere tutti gli eventi a cui sei iscritto.
          </p>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {data.userEvents.map((event: EventModel) => (
          <li
            key={event.title}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
          >
            <EventTicket
              event={event}
              qrCode={
                <QRNormal
                  value={event.subscribers[0].id} // TODO: Check what to put in the QR
                  size={70}
                  iconScale={0.2}
                />
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

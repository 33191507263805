import React, { useRef } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  AlertCircle,
  Image,
  Loader2,
  Plus,
  Send,
  Smile,
  WifiOff,
} from "lucide-react";
import { Fragment } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import ReplyPreview from "./ReplyPreview";
import { Chat } from "../models/chat";
import { useChatContext } from "../context/ChatContext";
import { useChatInput } from "../hooks/useChatInput";

interface ChatInputArea2Props {
  chat: Chat;
  currentUserId?: string;
  onMessageSent?: () => void;
}

const ChatInputArea2: React.FC<ChatInputArea2Props> = ({
  chat,
  currentUserId,
  onMessageSent,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const { replyingTo, setReplyingTo } = useChatContext();

  // Use our new hook for input functionality
  const {
    message,
    setMessage,
    sending,
    isConnected,
    hasDraft,
    isUploading,
    handleInputChange,
    handleSendTextMessage,
    handleSendImageMessage,
  } = useChatInput({
    chatId: chat.id,
    currentUserId,
    replyingTo,
    onReplyComplete: () => setReplyingTo(null),
    onMessageSent,
  });

  const onEmojiClick = (emojiData: EmojiClickData) => {
    const cursor = inputRef.current?.selectionStart || message.length;
    const newMessage =
      message.slice(0, cursor) + emojiData.emoji + message.slice(cursor);
    setMessage(newMessage);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        // Set cursor position after the inserted emoji
        const newPosition = cursor + emojiData.emoji.length;
        inputRef.current.setSelectionRange(newPosition, newPosition);
      }
    }, 0);
  };

  return (
    <div className="sticky bottom-0 left-0 right-0 z-10 p-1 sm:p-4 bg-white border-t border-gray-200">
      {!isConnected && (
        <div className="bg-amber-50 border-t border-amber-200 text-amber-800 text-sm flex items-center justify-center py-1 px-3 mb-2">
          <WifiOff size={16} className="mr-2" />
          <span>
            Connessione non disponibile. Il messaggio verrà salvato ed inviato
            quando sarai online.
          </span>
        </div>
      )}

      {hasDraft && isConnected && (
        <div className="bg-green-50 border-t border-green-200 text-green-700 text-sm flex items-center justify-center py-1 px-3 mb-2">
          <AlertCircle size={16} className="mr-2" />
          <span>Bozza salvata ripristinata</span>
        </div>
      )}

      {/* Hidden file input */}
      <input
        id="chat-image-upload"
        type="file"
        ref={fileInputRef}
        onChange={handleSendImageMessage}
        accept="image/*"
        className="hidden"
      />

      {replyingTo && (
        <ReplyPreview
          message={replyingTo}
          onCancel={() => setReplyingTo(null)}
        />
      )}

      <div className="pt-1 sm:p-4">
        <div className="flex items-center space-x-1">
          {/* Attachments menu */}
          <Menu as="div" className="relative">
            <MenuButton
              className="p-1 hover:bg-gray-100 rounded-full touch-manipulation"
              disabled={isUploading}
            >
              <span className="sr-only">Apri menù degli attachment</span>
              {isUploading ? (
                <Loader2 className="animate-spin text-gray-500" size={24} />
              ) : (
                <Plus size={24} className="text-gray-500" />
              )}
            </MenuButton>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute left-0 bottom-full mb-2 w-56 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ close }) => (
                      <label
                        htmlFor="chat-image-upload"
                        className="flex w-full items-center px-4 py-3 text-sm cursor-pointer"
                        onClick={() => {
                          // Close the menu after the label is clicked
                          close();
                        }}
                      >
                        <Image className="mr-3 h-5 w-5" />
                        Immagine
                      </label>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>

          {/* Text input area */}
          <div className="flex-1 relative">
            <input
              ref={inputRef}
              type="text"
              value={message}
              onChange={handleInputChange}
              placeholder="Scrivi un messaggio..."
              className="w-full pr-12 p-3 border rounded-full text-base focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              onKeyPress={(e) => e.key === "Enter" && handleSendTextMessage()}
              autoComplete="off"
              spellCheck="true"
            />

            {/* Emoji picker */}
            <Popover className="absolute right-3 top-1/2 -translate-y-1/2">
              <PopoverButton className="p-2 hover:bg-gray-100 rounded-full">
                <Smile className="h-5 w-5 text-gray-500" />
              </PopoverButton>

              <PopoverPanel
                className="absolute bottom-full right-0 mb-2 z-50"
                ref={pickerRef}
              >
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  width={300}
                  height={400}
                  searchPlaceholder="Cerca emoji..."
                  previewConfig={{ showPreview: false }}
                />
              </PopoverPanel>
            </Popover>
          </div>

          {/* Send button */}
          <button
            onClick={handleSendTextMessage}
            className={`p-3 rounded-full touch-manipulation ${
              message.trim()
                ? "bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white"
                : "bg-gray-100 text-gray-400"
            }`}
            aria-label="Invia messaggio"
            disabled={sending}
          >
            {sending ? (
              <Loader2 className="animate-spin" size={24} />
            ) : (
              <Send size={24} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChatInputArea2);

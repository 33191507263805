import React from "react";
import { ChatParticipant } from "../../models/chatParticipant";
import { Message } from "../../models/message";

interface ReplyContentProps {
  replyMessage: Message;
  currentUserId: string;
  participants: ChatParticipant[];
}

export const ReplyContent: React.FC<ReplyContentProps> = ({
  replyMessage,
  currentUserId,
  participants,
}) => {
  const isMyMessage = replyMessage.senderId === currentUserId;
  const sender = participants.find((p) => p.userId === replyMessage.senderId);
  const isImageMessage = replyMessage.content.type === "IMAGE";

  return (
    <div className="mb-1 relative w-full max-w-full">
      {/* WhatsApp-style vertical line */}
      <div
        className={`absolute left-0 top-0 bottom-0 w-1 ${
          isMyMessage ? "bg-emerald-500" : "bg-blue-500"
        }`}
      ></div>

      {/* Reply content */}
      <div
        className={`pl-2 pr-3 py-1.5 ml-1 text-xs overflow-hidden 
          ${
            isMyMessage
              ? "bg-emerald-50 text-gray-800"
              : "bg-gray-50 text-gray-800"
          }`}
      >
        {/* Sender name */}
        <p className="font-medium text-xs mb-0.5 truncate">
          {isMyMessage ? "Tu" : sender?.displayName || "Unknown"}
        </p>

        {/* Content */}
        {isImageMessage ? (
          <div className="flex items-center">
            {/* Image thumbnail - WhatsApp style is slightly smaller */}
            <div className="w-8 h-8 mr-2 flex-shrink-0 overflow-hidden rounded-sm">
              <img
                src={replyMessage.content.mediaUrl || ""}
                alt=""
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = `${process.env.PUBLIC_URL}/no-image.png`;
                }}
              />
            </div>

            {/* Image caption or placeholder */}
            <p className="truncate text-gray-500 max-w-[200px]">
              {replyMessage.content.text || "Photo"}
            </p>
          </div>
        ) : (
          <p className="text-gray-500 line-clamp-2 break-words">
            {replyMessage.content.text || "[messaggio mancante]"}
          </p>
        )}
      </div>
    </div>
  );
};
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import graphQLClient from "../../../services/gqlClients";

/**
 * MessageManager - Central service responsible for message synchronization across chats
 */
class MessageManager {
  private lastKnownMessageIds: Record<string, string> = {};

  constructor() {
    // Set up event listener for background messages
    window.addEventListener("background-message", this.handleBackgroundMessage);
  }

  /**
   * Get the Apollo client instance
   */
  get client(): ApolloClient<NormalizedCacheObject> {
    return graphQLClient;
  }

  /**
   * Track the latest message seen in a chat
   */
  trackLatestMessage(chatId: string, messageId: string): void {
    // Don't track temporary messages as sync points
    if (!messageId.startsWith("temp-")) {
      const currentLatest = this.lastKnownMessageIds[chatId];

      // Only log if it's a new ID to reduce console noise
      if (currentLatest !== messageId) {
        console.log(
          `MessageManager: Tracking message ${messageId} as latest for chat ${chatId}`
        );
        this.lastKnownMessageIds[chatId] = messageId;

        // Also persist in localStorage for recovery after page refresh
        try {
          localStorage.setItem(`latest_msg_${chatId}`, messageId);
        } catch (e) {
          console.warn("Failed to persist message ID to localStorage:", e);
        }
      }
    }
  }

  /**
   * Get the ID of the latest message we've seen for a chat
   */
  getLastKnownMessageId(chatId: string): string | undefined {
    // First try memory
    let messageId: string | undefined = this.lastKnownMessageIds[chatId];

    // If not in memory, try localStorage
    if (!messageId) {
      try {
        messageId = localStorage.getItem(`latest_msg_${chatId}`) || undefined;
        if (messageId) {
          // Restore to memory
          this.lastKnownMessageIds[chatId] = messageId;
        }
      } catch (e) {
        console.warn("Failed to read message ID from localStorage:", e);
      }
    }

    return messageId;
  }

  /**
   * Handle chat switch synchronization
   */
  synchronizeChat(
    chatId: string,
    fetchFn: (messageId?: string) => Promise<any>
  ): Promise<any> {
    const lastMessageId = this.getLastKnownMessageId(chatId);

    console.log(
      `MessageManager: Synchronizing chat ${chatId}${
        lastMessageId ? ` from message ${lastMessageId}` : ""
      }`
    );

    // If we have a last known message, fetch from there
    if (lastMessageId) {
      return fetchFn(lastMessageId);
    }

    // Otherwise just return a resolved promise
    return Promise.resolve(null);
  }

  /**
   * Handle background message events dispatched from WebSocket handler
   */
  private handleBackgroundMessage = (event: any): void => {
    const { chatId, messageId } = event.detail;
    if (chatId && messageId) {
      this.trackLatestMessage(chatId, messageId);
    }
  };
}

// Export as singleton
export const messageManager = new MessageManager();

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";

import config from "../config/config";
import { ACCESS_TOKEN_KEY } from "../consts";
import { MessageEdge } from "../features/chat/models/messageEdge";
import { ChatsConnection } from "../features/chat/models/chatConnection";
import { ChatsEdge } from "../features/chat/models/chatsEdge";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions?.code === "UNAUTHORIZED") {
        console.error("[GraphQL call] Unauthorized error:", err);
      }
    }
  }

  if (networkError) {
    console.error("Network error:", networkError);
  }
});

const httpLink = createHttpLink({
  uri: config.api.graphql,
});

const authLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem(ACCESS_TOKEN_KEY) ||
    sessionStorage.getItem(ACCESS_TOKEN_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export interface LifecycleAwareApolloClient
  extends ApolloClient<NormalizedCacheObject> {
  lifecycleState: {
    status: "normal" | "resumed" | "notification-launch";
    lastActiveTime: number;
    chatId?: string | null;
    messageId?: string | null;
  };

  notifyAppLaunchedFromNotification(chatId?: string, messageId?: string): void;
}

const cache = new InMemoryCache({
  typePolicies: {
    Message: {
      keyFields: ["id"],
      fields: {
        readBy: {
          // Custom merge function for readBy field
          merge(existing = [], incoming = []) {
            // If incoming is empty, return existing
            if (!incoming || !Array.isArray(incoming)) return existing || [];
            if (incoming.length === 0) return existing || [];

            // Create a map of existing read statuses by userId
            const existingMap = new Map();
            if (Array.isArray(existing)) {
              existing.forEach((item) => {
                if (item && item.userId) {
                  existingMap.set(item.userId, item);
                }
              });
            }

            // Create a new array with all unique read statuses
            const merged = Array.isArray(existing) ? [...existing] : [];

            // Add any new read statuses
            incoming.forEach((item) => {
              if (item && item.userId && !existingMap.has(item.userId)) {
                merged.push(item);
              }
            });

            return merged;
          },
        },
      },
    },
    Query: {
      fields: {
        messages: {
          keyArgs: ["chatId", "sortDirection"],
          merge(existing = { edges: [] }, incoming) {
            const existingIds = new Set(
              existing.edges.map((edge: MessageEdge) => edge.node.id)
            );
            const newEdges = incoming.edges.filter(
              (edge: MessageEdge) => !existingIds.has(edge.node.id)
            );

            // For DESC order (newest first), add new edges at the start
            return {
              ...incoming,
              edges:
                incoming.sortDirection === "DESC"
                  ? [...newEdges, ...existing.edges]
                  : [...existing.edges, ...newEdges],
              pageInfo: incoming.pageInfo,
            };
          },
        },
        chats: {
          keyArgs: false,
          merge(existing = { edges: [] }, incoming: ChatsConnection) {
            const existingChatIds = new Set(
              existing.edges.map((edge: ChatsEdge) => edge.node.id)
            );

            const newEdges = incoming.edges.filter(
              (edge) => !existingChatIds.has(edge.node.id)
            );

            return {
              ...incoming,
              edges: [...existing.edges, ...newEdges],
              pageInfo: incoming.pageInfo,
            };
          },
        },
      },
    },

    Event: {
      keyFields: ["id"],
    },
    EventSubscriber: {
      keyFields: ["id"],
    },
    Chat: {
      keyFields: ["id"],
      fields: {},
    },
    ChatParticipant: {
      keyFields: ["userId"],
      fields: {
        // Add this field policy to preserve avatarUrl
        avatarUrl: {
          // Only accept values that look like URLs
          merge(existing, incoming) {
            // If incoming is empty or doesn't look like a URL, keep existing
            if (
              !incoming ||
              (typeof incoming === "string" && !incoming.startsWith("http"))
            ) {
              return existing;
            }
            return incoming;
          },
        },
      },
    },
  },
});

const baseClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: cache,
  connectToDevTools: config.env === "development",
});

const graphQLClient = baseClient as LifecycleAwareApolloClient;

// Add lifecycle state
graphQLClient.lifecycleState = {
  status: "normal",
  lastActiveTime: Date.now(),
};

// Add lifecycle methods
graphQLClient.notifyAppLaunchedFromNotification = (
  chatId?: string,
  messageId?: string
) => {
  graphQLClient.lifecycleState.status = "notification-launch";
  graphQLClient.lifecycleState.chatId = chatId;
  graphQLClient.lifecycleState.messageId = messageId;
  graphQLClient.lifecycleState.lastActiveTime = Date.now();

  // Invalidate chat query cache if we have a chatId
  if (chatId) {
    // Evict just the messages for this chat
    graphQLClient.cache.evict({
      fieldName: "messages",
      args: { chatId },
    });

    // Optional: evict the chat itself to get fresh data
    graphQLClient.cache.evict({
      id: `Chat:${chatId}`,
    });

    graphQLClient.cache.gc();
  }
};

export default graphQLClient;

import React, { useCallback } from "react";
import { MessageEdge } from "../models/messageEdge";
import { ChatParticipant } from "../models/chatParticipant";
import { DateSeparator } from "./DateSeparator";
import { MessageImageHandler } from "./messages/MessageImageHandler";
import { MessageBubble } from "./messages/MessageBubble";
import { TypingBubble } from "./TypingBubble";

// Helper function for date labels
const getDateLabel = (date: Date): string => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Reset time parts to compare just the date
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const yesterdayDate = new Date(
    yesterday.getFullYear(),
    yesterday.getMonth(),
    yesterday.getDate()
  );
  const messageDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  if (messageDate.getTime() === todayDate.getTime()) {
    return "Oggi";
  } else if (messageDate.getTime() === yesterdayDate.getTime()) {
    return "Ieri";
  } else {
    return date.toLocaleDateString("it-IT", {
      weekday: "long",
      day: "numeric",
      month: "long",
    });
  }
};

// Sort messages by timestamp to ensure consistent ordering
const sortMessagesByTimestamp = (edges: MessageEdge[]) => {
  return [...edges].sort((a, b) => {
    const timeA = new Date(a.node.createdAt).getTime();
    const timeB = new Date(b.node.createdAt).getTime();

    if (timeA !== timeB) {
      return timeA - timeB; // Ascending by default
    }

    // Secondary sort by ID for consistency
    return a.node.id.localeCompare(b.node.id);
  });
};

interface MessageListProps {
  messages: MessageEdge[];
  currentUserId?: string;
  participants: ChatParticipant[];
  typingUsers: Set<string>;
  loading: boolean;
  chatId: string;
  messagesContainerRef: React.RefObject<HTMLDivElement>;
  loadMoreRef: React.RefObject<HTMLDivElement>;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  onScroll?: () => void;
  onMessageVisible?: (messageId: string) => void;
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  currentUserId = "",
  participants,
  typingUsers,
  loading,
  chatId,
  messagesContainerRef,
  loadMoreRef,
  messagesEndRef,
  onScroll,
  onMessageVisible,
}) => {
  // Observe messages for read status tracking
  const observeMessageElement = useCallback(
    (node: HTMLElement | null, messageId: string, senderId: string) => {
      if (node && senderId !== currentUserId && onMessageVisible) {
        // Use Intersection Observer API directly or call the callback
        onMessageVisible(messageId);
      }
    },
    [currentUserId, onMessageVisible]
  );

  // Render messages with date separators
  const renderMessages = () => {
    if (!messages || messages.length === 0) return null;

    const sortedMessages = sortMessagesByTimestamp(messages);
    const messagesWithDateSeparators: React.ReactNode[] = [];
    let currentDateLabel: string | null = null;

    sortedMessages.forEach((edge: MessageEdge) => {
      const message = edge.node;
      const messageDate = new Date(message.createdAt);
      const messageDateLabel = getDateLabel(messageDate);

      // Add date separator if this is a new date
      if (messageDateLabel !== currentDateLabel) {
        currentDateLabel = messageDateLabel;
        messagesWithDateSeparators.push(
          <DateSeparator
            key={`date-${messageDate.toISOString()}`}
            date={messageDateLabel}
          />
        );
      }

      // Create ref callback for read tracking
      const shouldObserve = message.senderId !== currentUserId;
      const refCallback = shouldObserve
        ? (node: HTMLElement | null) => {
            observeMessageElement(node, message.id, message.senderId);
          }
        : undefined;

      // Render appropriate message type
      if (
        ["UPLOADING_IMAGE", "FAILED_IMAGE", "IMAGE"].includes(
          message.content?.type || ""
        )
      ) {
        messagesWithDateSeparators.push(
          <MessageImageHandler
            key={message.id}
            ref={refCallback}
            message={message}
            currentUserId={currentUserId}
            chatId={chatId}
            participants={participants}
          />
        );
      } else {
        messagesWithDateSeparators.push(
          <MessageBubble
            key={message.id}
            ref={refCallback}
            message={message}
            currentUserId={currentUserId}
            participants={participants}
          />
        );
      }
    });

    return messagesWithDateSeparators;
  };

  return (
    <div
      ref={messagesContainerRef}
      className="flex-1 min-h-0 h-0 overflow-y-auto p-6 space-y-4"
      onScroll={onScroll}
    >
      <div ref={loadMoreRef} className="h-1" />

      {/* Loading state - only show when actively loading */}
      {loading && (
        <div className="text-center py-4">Caricamento messaggi...</div>
      )}

      {/* Empty chat state - show when not loading and no messages */}
      {!loading && messages.length === 0 && (
        <div className="text-center py-4 text-gray-500">
          Non ci sono messaggi. Inizia la conversazione!
        </div>
      )}

      {renderMessages()}

      {/* Typing indicators */}
      {Array.from(typingUsers).map((userId) => {
        const participant = participants.find((p) => p.userId === userId);
        if (participant && userId !== currentUserId) {
          return <TypingBubble key={userId} participant={participant} />;
        }
        return null;
      })}

      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;

import { useState, useEffect, useCallback } from "react";
import {
  launchStateManager,
  LaunchState,
} from "../services/launchStateManager";

/**
 * Hook to track and handle application launch state
 * Particularly useful for handling launches from notifications on iOS
 */
export function useAppLaunchState() {
  const [launchState, setLaunchState] = useState<LaunchState | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check URL params as a fallback mechanism
  const checkURLParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const chatId = params.get("chat_id");
    const messageId = params.get("message_id");

    if (chatId) {
      return {
        source: "direct" as const,
        chatId,
        messageId: messageId || null,
        timestamp: Date.now(),
      };
    }
    return null;
  }, []);

  useEffect(() => {
    // First, check for service worker messages
    const handleServiceWorkerMessage = (event: MessageEvent) => {
      if (event.data?.type === "NOTIFICATION_LAUNCH") {
        const newLaunchState = event.data.launchState;
        launchStateManager.saveLaunchState(newLaunchState);
        setLaunchState(newLaunchState);
        setIsLoading(false);
      }
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleServiceWorkerMessage
    );

    // Let's check all possible sources in order of priority
    (async () => {
      setIsLoading(true);

      // First try both session storage and Cache API
      const savedState = await launchStateManager.getLaunchStateAsync();
      if (savedState) {
        console.log("Found launch state in storage", savedState);
        setLaunchState(savedState);
        setIsLoading(false);
        return;
      }

      // If not found, check URL params
      const urlState = checkURLParams();
      if (urlState) {
        console.log("Found launch state in URL params", urlState);
        await launchStateManager.saveLaunchState(urlState);
        setLaunchState(urlState);
        setIsLoading(false);
        return;
      }

      // If still nothing, ask service worker
      if (navigator.serviceWorker.controller) {
        console.log("Asking service worker for launch state");
        navigator.serviceWorker.controller.postMessage({
          type: "CHECK_LAUNCH_STATE",
        });

        // Wait a bit for service worker to respond
        setTimeout(() => setIsLoading(false), 300);
      } else {
        console.log("No service worker controller available");
        setIsLoading(false);
      }
    })();

    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleServiceWorkerMessage
      );
    };
  }, [checkURLParams]);

  // Clear the launch state (in all storage mechanisms)
  const clearLaunchState = useCallback(async () => {
    await launchStateManager.clearLaunchStateAsync();
    setLaunchState(null);
  }, []);

  return { launchState, clearLaunchState, isLoading };
}

import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Message, MessageStatus } from "../models/message";
import { MESSAGE_QUERY } from "../api/queries";
import { MESSAGE_FIELDS_FRAGMENT } from "../api/fragments";

export function useReplyMessage(
  messageId: string | null | undefined,
  chatId: string
): Message | null {
  const [replyMessage, setReplyMessage] = useState<Message | null>(null);
  const client = useApolloClient();

  useEffect(() => {
    if (!messageId || messageId === "000000000000000000000000" || !chatId) {
      setReplyMessage(null);
      return;
    }

    // Check cache first
    let cachedMessage = null;
    try {
      cachedMessage = client.readFragment({
        id: messageId,
        fragment: MESSAGE_FIELDS_FRAGMENT,
      });
    } catch (e) {
      console.log("Error reading from cache with direct ID");
    }

    if (cachedMessage) {
      console.log("Found reply in cache:", cachedMessage);
      setReplyMessage(cachedMessage);
      return;
    }

    // Set placeholder immediately while we fetch
    setReplyMessage({
      id: messageId,
      chatId: chatId || "",
      senderId: "",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      content: {
        __typename: "MessageContent",
        type: "TEXT",
        text: "[messaggio non disponibile]",
      },
      status: "SENT" as MessageStatus,
      readBy: [],
      reactions: [],
      __typename: "Message",
    } as Message);

    // Fetch the actual message
    client
      .query({
        query: MESSAGE_QUERY,
        variables: { chatId, messageId },
      })
      .then(({ data }) => {
        if (data?.message) {
          console.log("Fetched reply message:", data.message);
          setReplyMessage(data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching reply message:", err);
      });

    // Clean up if the component unmounts
    return () => {
      // Cancel any pending operations if needed
    };
  }, [messageId, chatId, client]);

  return replyMessage;
}
